export default {
  productDescription: (name) =>
    `Vul je gegevens in en wij sturen jouw <strong>McDonald\'s ${name}</strong> zo snel mogelijk naar je toe.`,

  // Sections:
  personalInformation: 'Persoonlijke gegevens',
  address: 'Adres',

  // Fields:
  firstName: 'Voornaam',
  lastName: 'Achternaam',
  postalCode: 'Postcode',
  houseNumber: 'Huisnummer',
  suffix: 'Toevoeging',
  streetName: 'Straat',
  venue: 'Plaats',

  // Terms:
  termsHref:
    'https://www.mcdonalds.com/nl/nl-nl/algemene_voorwaarden.html?openOutsideMcd=true',
  terms: 'Ja, mijn gegevens kloppen en ik ga akkoord met de',
  link: 'algemene voorwaarden',

  // Errors:
  firstNameError: 'Voer een geldige voornaam in',
  lastNameError: 'Voer een geldige achternaam in',
  addressError: 'Voer een geldige adres in',
  postalCodeError: 'Voer een geldige postcode in',
  houseNumberError: 'Voer een geldig huisnummer in',
  suffixError: 'Voer een geldige toevoeging in',
  streetNameError: 'Voer een geldige straatnaam in',
  venueError: 'Verplicht veld',
  termsError: 'Verplicht',

  // Submit:
  submit: 'Versturen',
};
