const POSTCODE_URL_API_STAGING =
  'https://europe-west3-mcdonald-s-rewards-staging.cloudfunctions.net/getPostcode';

const POSTCODE_URL_API_PRODUCTION =
  'https://europe-west3-mcdonald-s-rewards-production.cloudfunctions.net/getPostcode';

export const POSTCODE_URL_API =
  process.env.REACT_APP_ENV === 'staging'
    ? POSTCODE_URL_API_STAGING
    : POSTCODE_URL_API_PRODUCTION;

console.info('Environment', process.env.REACT_APP_ENV);
