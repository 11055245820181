import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useOnMount } from 'hooks';
import Content from 'routes/Login/content';
import { promptLogin } from 'utils/_mcd-connector';
import { getUserEmail } from 'redux/user/selectors';

import { ProductAnimation, GradientText, Button } from 'components/shared';
import { LoginWrapper, TextWrapper, LoginText } from './Login.styles';
import { AvatarImg } from '../../theme/globalStyles';
import { FamilyBG } from '../../assets/images';
import { getIsFamilyUser } from '../../redux/user/selectors';

const Login = ({ userEmail, isFamilyUser }) => {
  const navigate = useNavigate();
  useOnMount(Content.gtmMount);

  useEffect(() =>
    userEmail && navigate('/')
  , [userEmail, navigate]);

  const onLoginClick = () =>
    promptLogin()
      .then(() => navigate('/'))
      .catch(error => console.error('Login Error:', error));


  return (
    <LoginWrapper isFamily={isFamilyUser}>
      <ProductAnimation fanshop />

      {isFamilyUser && <AvatarImg src={FamilyBG} />}

      <TextWrapper>
        <GradientText tag="h1" text={Content.login} isFamily={isFamilyUser}/>

        <LoginText
          dangerouslySetInnerHTML={{
            __html: Content.description,
          }}
        />
      </TextWrapper>

      <Button
        gtmConfig={Content.gtmButton}
        onClick={onLoginClick}
        text={Content.login}
        isFamily={isFamilyUser}
        width="100%"
      />
    </LoginWrapper>
  );
};

Login.propTypes = {
  userEmail: PropTypes.string.isRequired,
  isFamilyUser: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isFamilyUser: getIsFamilyUser(state),
  userEmail: getUserEmail(state),
});

export default connect(mapStateToProps)(Login);
