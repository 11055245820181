import styled, { css } from 'styled-components';

export default styled.div(({ isOverlay }) => css`
  position: fixed;
  display: block;

  padding: 30%;
  z-index: 99;
  margin: 0;

  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  ${isOverlay && css`
    background-color: rgba(0, 0, 0, 0.5);
  `}
`);
