import styled from 'styled-components';

export const CardsContainer = styled.div`
  flex-direction: column;
  align-content: center;
  margin: 40px 0px 13px;
  align-items: center;

  row-gap: 13px;
  display: flex;
  width: 100%;

  > img {
    object-position: center;
    border-radius: 15px;
    object-fit: cover;

    height: 155px;
    width: 100%;
  }
`;
