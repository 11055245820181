import {
  Coins,
  Circle,
  CrewLogo,
  InputField,
  PopupLogin,
  ErrorMessage,
  PopupContent,
  PopupContainer,
  InputContainer,
} from './CrewLoginPopup.styles';

import store from 'redux/store';
import { useLoader } from 'hooks';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkCrewLogin } from 'utils/_app-helpers';
import React, { useRef, useState, useEffect } from 'react';
import { setIsCrewUser } from '../../../redux/user/actions';
import { CrewCoins, CrewLogo as Logo } from 'assets/images';
// import { getUserEmail, getDealsData } from 'redux/user/selectors';

const CrewLoginPopup = (/* { email, deals } */) => {
  const crewCode = useRef(null);
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  // const [focus, setFocus] = useState(false);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(true);

  const loader = useLoader(loading, true);
  const code = useRef(Array.from({ length: 4 }).fill(''));
  // const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const onFocus = (event) => {
    const index = +(event.target.dataset.index ?? 0);
    code.current[index] = event.target.value = '';
    // focusTime.current = Date.now();
    // !isIOS && setFocus(true);
  };

  const onInput = (event) => {
    const index = +(event.target.dataset.index ?? 0);
    const next = crewCode.current?.children[index + 1];
    event.target.value = event.target.value.slice(-1);

    next ? next.focus() : event.target.blur();
    code.current[index] = event.target.value;
    setActive(!code.current.includes(''));
    setError(false);
  };

  // const onBlur = () => setFocus(false);

  const onLogin = async () => {
    try {
      setLoading(true);
      const valid = await checkCrewLogin(code.current.join(''));
      if (!valid) return setError(true);

      store.dispatch(setIsCrewUser(true));
      setError(false);
      navigate('/');
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(
    () => {
      // if (!email || typeof deals !== 'number') return;
      const delay = setTimeout(() => setLoading(false), 2e3);
      return () => clearTimeout(delay);
    },
    [
      /* email, deals */
    ]
  );

  return (
    // onClick={onBlur}
    <PopupContainer>
      <Circle bottom={-52.5} radius={95.38} alpha={0.1} />
      <Circle bottom={-45.0} radius={76.58} alpha={0.3} />
      <Circle bottom={-37.5} radius={55.29} alpha={0.5} />

      {/* style={{ transform: `translate(-50%, ${-focus * 20}vh)` }} */}

      <PopupContent>
        <CrewLogo src={Logo} />

        <PopupLogin disabled={!active}>
          <h2>Ben jij crew?</h2>
          <span>Vul je restaurant code in</span>

          <InputContainer ref={crewCode}>
            {code.current.map((_, c) => (
              <InputField
                inputmode="numeric"
                autoComplete="off"
                name={`code-${c}`}
                onChange={onInput}
                onFocus={onFocus}
                pattern="[0-9]*"
                // onBlur={onBlur}
                data-index={c}
                error={error}
                maxLength="1"
                type="number"
                key={c}
              />
            ))}
          </InputContainer>

          {error && (
            <ErrorMessage>
              Helaas, de inlog code is incorrect. Weet je de code niet? Neem dan
              contact op met jouw restaurant manager.
            </ErrorMessage>
          )}

          <button onClick={onLogin}>Inloggen</button>
        </PopupLogin>
      </PopupContent>

      {/* style={{ transform: `translate(-50%, ${-focus * 20}vh)` }} */}

      <Coins src={CrewCoins} />

      {loader}
    </PopupContainer>
  );
};

// CrewLoginPopup.propTypes = {
//   email: PropTypes.string.isRequired,
//   deals: PropTypes.number.isRequired,
// };

// const mapStateToProps = (state) => ({
//   email: getUserEmail(state),
//   deals: getDealsData(state),
// });

export default CrewLoginPopup; // connect(mapStateToProps)(CrewLoginPopup);
