export default {
  productDescription: (userName, productName, price, change) =>
    // eslint-disable-next-line max-len
    `Hi ${userName}, jij wordt de trotse eigenaar van de <strong>${productName}</strong> in ruil voor <strong>${price} punten</strong>. Na je bestelling heb je <strong>${change} punten</strong> over.`,

  addressDescription: `We sturen het product naar:`,

  // Buttons:
  confirm: 'Bevestigen',
  back: 'Terug',

  gtmConfirmButton: (code) => ({
    eventLabel: `/rewardshop/thanks/${code}`,
    eventAction: 'Bevestigen',
    event: 'button_click',
  }),

  gtmBackButton: (code) => ({
    eventLabel: `/rewardshop/form/${code}`,
    event: 'button_click',
    eventAction: 'Terug',
  }),
};
