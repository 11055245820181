import { createSelector } from 'reselect';

export const getProductState = createSelector(
  [(state) => state.product],
  (productState) => productState
);

export const getProductCode = createSelector(
  [getProductState],
  (productState) => productState.code
);

export const getProductDescription = createSelector(
  [getProductState],
  (productState) => productState.description
);

export const getProductImageURL = createSelector(
  [getProductState],
  (productState) => productState.image
);

export const getProductName = createSelector(
  [getProductState],
  (productState) => productState.name
);

export const getProductPlexure = createSelector(
  [getProductState],
  (productState) => productState.plexure
);

export const getProductPrice = createSelector(
  [getProductState],
  (productState) => productState.price
);
