import { CrewPopup } from 'assets/images';
import styled, { css } from 'styled-components';

export const PopupContainer = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.gold};
    border-radius: 16px 16px 0px 0px;
    position: absolute;
    display: block;

    height: 100%;
    width: 100%;
    bottom: 0;
  `
);

export const Circle = styled.div(
  ({ bottom, radius, alpha }) => css`
    background-color: rgb(255 255 255 / ${alpha});

    transform: translateX(-50%);
    height: ${radius * 2}vw;
    width: ${radius * 2}vw;

    bottom: ${bottom}%;
    border-radius: 50%;
    position: absolute;

    display: block;
    left: 50%;
  `
);

export const PopupContent = styled.div(
  () => css`
    --width: min(92.3vw, 360px);
    --height: calc(var(--width) / 9 * 11);

    transform: translateX(-50%);
    background-image: url(${CrewPopup});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    height: var(--height);
    width: var(--width);
    position: absolute;
    bottom: 21.327vh;

    // position: relative;
    // top: 26.327vh;
    left: 50%;
  `
);

export const PopupLogin = styled.div(
  ({ theme, disabled }) => css`
    color: ${theme.colors.black};
    padding: min(15vh, 120px) 26px 36px;
    text-align: center;
    position: relative;
    height: 100%;

    h2 {
      font-family: ${theme.fontFamily.secondary};
      font-size: min(14.36vw, 56px);
      text-transform: uppercase;
      margin-bottom: 4px;
      line-height: 120%;
    }

    span {
      letter-spacing: 0.6px;
      margin-bottom: 24px;
      line-height: 120%;
      font-size: 20px;
      display: block;
      opacity: 0.8;
    }

    button {
      font-family: ${theme.fontFamily.secondary};
      background-color: ${theme.colors.gold};
      color: ${theme.colors.black};

      text-transform: uppercase;
      padding: 17px 40px 15px;
      letter-spacing: 1.44px;
      border-radius: 50px;
      margin: 24px auto 0;

      line-height: 22px;
      font-weight: bold;
      font-size: 18px;
      display: block;

      &:hover,
      &:focus,
      &:active {
        color: ${theme.colors.black};
      }

      ${disabled &&
      css`
        background-color: ${theme.colors.silvery};
        color: ${theme.colors.smokeGrey};
        pointer-events: none;
      `}
    }
  `
);

export const InputContainer = styled.div`
  justify-content: center;
  flex-direction: row;
  position: relative;
  text-align: center;
  margin: 0 auto;
  display: flex;
  gap: 16px;
`;

export const InputField = styled.input(
  ({ theme, error }) => css`
    font-family: ${theme.fontFamily.secondary};
    background-color: #f4f3f8;
    caret-color: #000000;
    border-radius: 12px;

    isolation: isolate;
    padding: 16px 20px;
    font-weight: bold;
    line-height: 120%;

    font-size: 28px;
    color: #000000;
    border: none;
    height: 60px;
    width: 52px;

    ${error &&
    css`
      background-color: #ffd9d9;
    `}

    &:focus {
      outline: none;
    }
  `
);

export const ErrorMessage = styled.em`
  letter-spacing: 0.5px;
  font-style: normal;
  position: relative;
  line-height: 120%;

  margin-top: 8px;
  font-size: 16px;
  display: block;
  color: #eb0000;
`;

export const CrewLogo = styled.img`
  --size: min(41vw, 160px);

  transform: translateX(-50%);
  height: var(--size);
  width: var(--size);
  position: absolute;

  left: 50%;
  top: -16%;
`;

export const Coins = styled.img(
  () => css`
    transform: translateX(-50%);
    pointer-events: none;
    position: absolute;
    width: 119.487vw;
    display: block;
    bottom: 4.5vh;
    height: auto;
    left: 50%;
  `
);
