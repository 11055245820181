import { useEffect } from 'react';

export const pushEvent = config => {
  window.dataLayer ??= [];
  window.dataLayer.push(config);
};

export const useOnMount = config => {
  // Need to disable eslint here 'cause React will trigger `pushEvent` multiple
  // times if `config` object will be included in the dependency array. -.-
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => pushEvent(config), []);
};
