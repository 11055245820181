export const ADD_USER_DATA = 'ADD_USER_DATA';
export const ADD_USER_DATA_FAILED = 'ADD_USER_DATA_FAILED';

export const ADD_DEALS_DATA = 'ADD_DEALS_DATA';
export const ADD_DEALS_DATA_FAILED = 'ADD_DEALS_DATA_FAILED';

export const IS_PRODUCT_ORDERED = 'IS_PRODUCT_ORDERED';
export const IS_PRODUCT_ORDERED_FAILED = 'IS_PRODUCT_ORDERED_FAILED';

export const IS_CREW_USER = 'IS_CREW_USER';
export const IS_CREW_USER_FAILED = 'IS_CREW_USER_FAILED';

export const IS_FAMILY_USER = 'IS_FAMILY_USER';
export const IS_FAMILY_USER_FAILED = 'IS_FAMILY_USER_FAILED';

export const addUserData = (data) => (dispatch) => {
  try {
    dispatch({ type: ADD_USER_DATA, data });
  } catch (error) {
    dispatch({ type: ADD_USER_DATA_FAILED, error });
  }
};

export const addDealsData = (data) => (dispatch) => {
  try {
    dispatch({ type: ADD_DEALS_DATA, data });
  } catch (error) {
    dispatch({ type: ADD_DEALS_DATA_FAILED, error });
  }
};

export const setIsProductOrdered = (data) => (dispatch) => {
  try {
    dispatch({ type: IS_PRODUCT_ORDERED, data });
  } catch (error) {
    dispatch({ type: IS_PRODUCT_ORDERED_FAILED, error });
  }
};

export const setIsCrewUser = (data) => (dispatch) => {
  try {
    dispatch({ type: IS_CREW_USER, data });
  } catch (error) {
    dispatch({ type: IS_CREW_USER_FAILED, error });
  }
};

export const setIsFamilyUser = (data) => (dispatch) => {
  try {
    dispatch({ type: IS_FAMILY_USER, data });
  } catch (error) {
    dispatch({ type: IS_FAMILY_USER_FAILED, error });
  }
};
