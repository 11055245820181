import styled, { css } from 'styled-components';

export default styled.p(({ theme }) => css`
  margin-bottom: ${theme.spacings.sp16};
  line-height: ${theme.typography.t26};
  font-size: ${theme.typography.t18};

  letter-spacing: normal;
  position: relative;
  display: block;
  width: 100%;
`);
