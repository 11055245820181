import styled, { css } from 'styled-components';

export default styled.button(({ theme, width, yellow, disabled, isFamily }) => css`
  background-image: ${theme.colors.orangeToPink};
  font-family: ${theme.fontFamily.base};
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.typography.t18};

  color: ${theme.colors.white};
  backface-visibility: hidden;
  transition: filter 0.5s;

  letter-spacing: normal;
  padding: 12.5px 20px;
  border-radius: 23px;
  text-align: center;

  position: relative;
  line-height: 26px;
  max-width: 250px;
  width: ${width};

  cursor: pointer;
  display: block;
  margin: 0 auto;
  height: 48px;

  ${yellow && css`
    background-image: ${theme.colors.orangeToYellow};
    margin-top: ${theme.spacings.sp16};
  `}

  ${isFamily && css`
    background: ${theme.colors.yellow};
    margin-top: ${theme.spacings.sp16};
    color: ${theme.colors.black};
  `}

  ${disabled && css`
    filter: grayscale(0.5);
    pointer-events: none;
  `}
`);
