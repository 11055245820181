import styled, { css } from 'styled-components';

export const Text = styled.small(({ theme }) => {
  const { colors, typography } = theme;

  return css`
    display: block;
    font-size: ${typography.t5};
    font-weight: bold;
    line-height: 1.5rem;
    text-align: center;
    color: ${colors.lightRed};
    max-width: 300px;
    margin: 0 auto;
    user-select: none;
  `;
});
