export default {
  userInfo: (name) => `Hi ${name},<br />Dit zijn jouw punten:`,

  productsDescription:
    // eslint-disable-next-line max-len
    "Genoeg punten verzameld? Naast te gekke McDonald’s merchandise, kan je nu ook kiezen voor korting op toffe uitjes! Elke dinsdag vullen we de merch weer aan. Check hier hoe je punten spaart!",

  crewProductsDescription:
    // eslint-disable-next-line max-len
    "Genoeg punten verzameld? Naast te gekke McDonald’s merchandise, kan je nu ook kiezen voor korting op toffe uitjes! Elke dinsdag vullen we de merch weer aan. Check hier hoe je punten spaart!",

  gtmMount: {
    event: 'page_view',
    eventProperties: {
      page_virtual_path: '/rewardshop/overview',
      page_type: 'rewardshop',
    },
  },

  gtmProduct: (code) => ({
    eventLabel: `/rewardshop/${code}`,
    eventAction: `Select ${code}`,
    event: 'button_click',
  }),
};
