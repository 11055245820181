import { pushEvent } from 'hooks';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { PopupOverlay, PopupContainer, CloseButton } from './ZoomPopup.styles';

const ZoomPopup = ({ images, onClose, code }) => {
  const onZoomStop = ({ state }) => state.scale === 1 && setSwipeable(true);
  const onZoomStart = () => swipeable && setSwipeable(false);

  const [swipeable, setSwipeable] = useState(true);
  const [visible, setVisible] = useState(false);

  const onClick = () => {
    setTimeout(onClose, 400);
    setVisible(false);

    pushEvent({
      eventLabel: `/rewardshop/sufficient/${code}`,
      eventAction: 'Close zoom',
      event: 'button_click',
    });
  };

  useEffect(() => setTimeout(() => setVisible(true)), []);

  return (
    <PopupOverlay visible={visible}>
      <PopupContainer>
        <CloseButton onClick={onClick} />

        <Carousel
          showIndicators={images.length > 1}
          showArrows={images.length > 1}
          swipeable={swipeable}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
        >
          {images.map((image, i) => (
            <TransformWrapper
              onZoomStart={onZoomStart}
              onZoomStop={onZoomStop}
              disablePadding
              centerOnInit
              key={i}
            >
              <TransformComponent>
                <img src={image} />
              </TransformComponent>
            </TransformWrapper>
          ))}
        </Carousel>
      </PopupContainer>
    </PopupOverlay>
  );
};

ZoomPopup.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
};

export default ZoomPopup;
