import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/shared';
import Content from 'components/Form/content/popup';

import {
  PopupWrapper,
  PopupContainer,
  DescriptionContent,
  AddressDescription,
} from './ConfirmationPopup.styles';

const ConfirmationPopup = ({
  productName,
  userPoints,
  userInfo,
  price,
  code,
  onConfirm,
  onCancel,
}) => {
  const isSubmitting = useRef(false);
  const [visible, setVisible] = useState(false);
  useEffect(() => setTimeout(() => setVisible(true)), []);

  const onClose = (confirm) => {
    if (isSubmitting.current) return;
    setVisible(false);

    isSubmitting.current = confirm;
    setTimeout(confirm ? onConfirm : onCancel, 750);
  };

  return (
    <PopupWrapper visible={visible}>
      <PopupContainer>
        <DescriptionContent
          dangerouslySetInnerHTML={{
            __html: Content.productDescription(
              userInfo.firstName,
              productName,
              price,
              Math.max(userPoints - price, 0)
            ),
          }}
        />

        <AddressDescription>
          {Content.addressDescription}
          <strong>
            {userInfo.street} {userInfo.houseNumber}
            {userInfo.extension && `-${userInfo.extension}`}
          </strong>

          <strong>{userInfo.postalCode}</strong>
          <strong>{userInfo.city}</strong>
        </AddressDescription>

        <Button
          gtmConfig={Content.gtmConfirmButton(code)}
          onClick={() => onClose(true)}
          text={Content.confirm}
          width="100%"
        />

        <Button
          gtmConfig={Content.gtmBackButton(code)}
          onClick={() => onClose(false)}
          text={Content.back}
          width="100%"
          yellow
        />
      </PopupContainer>
    </PopupWrapper>
  );
};

ConfirmationPopup.propTypes = {
  productName: PropTypes.string.isRequired,
  userPoints: PropTypes.number.isRequired,

  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,

  price: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,

  userInfo: PropTypes.shape({
    street: PropTypes.string.isRequired,
    houseNumber: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,

    firstName: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    extension: PropTypes.string,
  }).isRequired,
};

export default ConfirmationPopup;
