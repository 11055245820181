import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/shared';
import Content from 'components/Product/content';

import {
  UserPoints,
  ProductName,
  DescriptionText,
  DescriptionContainer,
} from './ProductDescription.styles';
import { getIsFamilyUser } from '../../../redux/user/selectors';
import { connect } from 'react-redux';

const ProductDescription = ({ code, name, description, price, points, productType, externalLink, isFamilyUser }) => {
  const notEnough = points < price;
  const navigate = useNavigate();

  return (
    <DescriptionContainer>
      <DescriptionText>{description}</DescriptionText>

      <DescriptionText
        dangerouslySetInnerHTML={{
          __html: notEnough
            ? Content.missingPoints(price - points)
            : Content.question(price),
        }}
      />

      <ProductName>
        {name}
        {!notEnough && '?'}
      </ProductName>

      {(notEnough || (!notEnough && productType === 2 && !externalLink)) ? (
        <Button
          gtmConfig={Content.gtmBackButton}
          onClick={() => navigate('/')}
          text={Content.back}
          width="100%"
          yellow
        />
      ) : (
        (productType === 2 && externalLink) ?
          <Button
            text={Content.continue}
            link={externalLink}
            isFamily={isFamilyUser}
            width="100%"
          /> :
          <Button
            gtmConfig={Content.gtmFormButton(code)}
            onClick={() => navigate('/form')}
            text={Content.continue}
            isFamily={isFamilyUser}
            width="100%"
          />
      )}

      <UserPoints
        dangerouslySetInnerHTML={{
          __html: Content.totalPoints(points),
        }}
      />
    </DescriptionContainer>
  );
};

ProductDescription.propTypes = {
  isFamilyUser: PropTypes.bool.isRequired,

  favorite: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,

  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  description: PropTypes.string,

  productType: PropTypes.number,
  externalLink: PropTypes.string,
};

ProductDescription.defaultProps = {
  description: '',
  productType: 1,
  externalLink: null
};

const mapStateToProps = (state) => ({
  isFamilyUser: getIsFamilyUser(state),
});

export default connect(mapStateToProps)(ProductDescription);
