import styled, { css } from 'styled-components';
import { InputField } from '../InputField/InputField.styles';

export default styled.select(({ theme, width }) => css`
  ${InputField()}
  width: ${width}%;

  > option {
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};

    appearance: none;
    outline: none;

    border: none;
    height: 50px;

    &:hover, &:focus, &:checked, &:selected {
      background-color: ${theme.colors.darkWhite};
    }

    &:first-child {
      display: none;
    }
  }

  &:focus {
    border-color: ${theme.colors.yellow};

    > option:checked {
      background-color: ${theme.colors.border};
    }
  }
`);
