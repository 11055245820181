import styled, { css } from 'styled-components';
import { CloseIcon } from 'assets/images';
import { Arrow } from 'assets/images';

export const PopupOverlay = styled.div(
  ({ visible }) => css`
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.4s;
    position: fixed;

    height: 100%;
    width: 100%;

    z-index: 8;
    padding: 0;
    margin: 0;

    bottom: 0;
    right: 0;
    left: 0;
    top: 0;

    ${visible &&
    css`
      background-color: rgba(0, 0, 0, 0.6);

      > div {
        transition-timing-function: ease-out;
        transition-delay: 0s;
        transform: scale(1);
        opacity: 1;
      }
    `}
  `
);

export const PopupContainer = styled.div(
  ({ theme }) => css`
    transition: transform 0.4s ease-in, opacity 0.25s 0.15s;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: ${theme.colors.white};

    backface-visibility: hidden;
    // padding: min(3.94vh, 32px);

    transform-origin: 50% 50%;
    transform: scale(0.75);
    top: min(3.94vh, 32px);

    border-radius: 22px;
    position: absolute;

    max-height: 544px;
    max-width: 425px;

    display: block;
    margin: 0 auto;

    height: 128vw;
    width: 100vw;

    opacity: 0;
    right: 0;
    left: 0;

    > .carousel-root,
    > .carousel-root > .carousel-slider:first-child {
      height: 100%;
    }

    > .carousel-root > .carousel-slider:first-child .control-arrow {
      padding: 0 20px 0 0;
      background: none;

      margin: auto;
      height: 30px;
      width: 49px;

      opacity: 1;

      &::before {
        background-image: url('${Arrow}');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        border: none;
        height: 100%;
        width: 100%;
        margin: 0;
      }

      &.control-prev {
        padding: 0 0 0 20px;

        &::before {
          transform: rotate(-180deg);
        }
      }
    }

    > .carousel-root > .carousel-slider:first-child .dot {
      background-color: #292929;
      box-shadow: none;
    }

    > .carousel-root > .carousel-slider:first-child > .slider-wrapper {
      transform: translateY(-50%);
      position: relative;
      top: 50%;
    }
  `
);

export const CloseButton = styled.div`
  background-image: url(${CloseIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  right: min(6.4vw, 27.2px);
  top: min(6.4vw, 27.2px);

  height: min(5vw, 21px);
  width: min(5vw, 21px);

  position: absolute;
  display: block;
  z-index: 1;
`;
