export const ADD_PRODUCT_DATA = 'ADD_PRODUCT_DATA';
export const ADD_PRODUCT_DATA_FAILED = 'ADD_PRODUCT_DATA_FAILED';

export const ADD_PRODUCT_ORDERID = 'ADD_PRODUCT_ORDERID';
export const ADD_PRODUCT_ORDERID_FAILED = 'ADD_PRODUCT_ORDERID_FAILED';

export const addProductData = (data) => (dispatch) => {
  try {
    dispatch({ type: ADD_PRODUCT_DATA, data });
  } catch (error) {
    dispatch({ type: ADD_PRODUCT_DATA_FAILED, error });
  }
};

export const addProductOrderId = (data) => (dispatch) => {
  try {
    dispatch({ type: ADD_PRODUCT_ORDERID, data });
  } catch (error) {
    dispatch({ type: ADD_PRODUCT_ORDERID_FAILED, error });
  }
};
