import styled, { css } from 'styled-components';

export default styled.div(({ top }) => css`
  // "aspect-ratio" won't work for iOS < 14.9,
  // but still it's better than without it:
  aspect-ratio: 0.74935475;

  pointer-events: none;
  position: relative;

  max-width: 425px;
  display: block;

  height: auto;
  width: 100%;
  z-index: 1;

  top: ${top}px;
  left: 0;

  .product {
    pointer-events: all;
  }
`);
