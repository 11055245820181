import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import store from 'redux/store';

import { useOnMount } from 'hooks';
import Content from 'routes/Thanks/content';
import { getDealsPoints } from 'utils/_app-helpers';

import {setIsProductOrdered} from "../../redux/user/actions";
import { getUserEmail, getIsFamilyUser } from 'redux/user/selectors';
import { getProductCode, getProductName } from 'redux/product/selectors';
import { ProductAnimation, GradientText, Button } from 'components/shared';
import { AvatarImg } from '../../theme/globalStyles';
import { FamilyBG } from '../../assets/images';

import {
  ThanksWrapper,
  TextWrapper,
  ThanksText,
  UserEmail,
} from './Thanks.styles';

const Thanks = ({ productCode, productName, userEmail, isFamilyUser }) => {
  const isGTMSettings = window.location.href.indexOf('gtm-desktop') > -1;
  const isDevSettings = process.env.NODE_ENV === 'development';

  getDealsPoints(isDevSettings || isGTMSettings);
  const navigate = useNavigate();

  useOnMount({
    event: 'page_view',
    eventProperties: {
      page_virtual_path: `/rewardshop/thanks/${productCode}`,
      page_type: 'rewardshop',
    },
  });

  useEffect(() => {
    store.dispatch(setIsProductOrdered(true));
  },[]);


  return (
    <ThanksWrapper isFamily={isFamilyUser}>
      <ProductAnimation top={-100} fanshop />

      {isFamilyUser && <AvatarImg src={FamilyBG} />}

      <TextWrapper>
        <GradientText tag="h1" text={Content.thanks} isFamily={isFamilyUser}/>

        <ThanksText
          dangerouslySetInnerHTML={{
            __html: Content.description(productName),
          }}
        />

        <UserEmail>{userEmail}</UserEmail>
        <ThanksText>{Content.orderPlaced}</ThanksText>
      </TextWrapper>

      <Button
        gtmConfig={Content.gtmButton}
        onClick={() => navigate('/')}
        text={Content.dashboard}
        isFamily={isFamilyUser}
        width="100%"
      />
    </ThanksWrapper>
  );
};

Thanks.propTypes = {
  productCode: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  isFamilyUser: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  productCode: getProductCode(state),
  productName: getProductName(state),
  userEmail: getUserEmail(state),
  isFamilyUser: getIsFamilyUser(state),
});

export default connect(mapStateToProps)(Thanks);
