import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import appReducer from './reducers';
import { initialState as userInitialState } from './user/reducer';

const composeEnhancers = composeWithDevTools({});
const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const { logger } = require('redux-logger');
  middlewares.push(logger);
}

const initialState = {
  user: userInitialState
};

const store = createStore(
  appReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares))
);

export default store;
