import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button } from 'components/shared';
import Content from './content';

import {
  PopupWrapper,
  PopupContainer,
  DescriptionContent,
} from './OutOfStockPopup.styles';

const OutOfStockPopup = ({ productName, code }) => {
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  useEffect(() => setTimeout(() => setVisible(true)), []);

  const onClose = () => {
    // document.location.href = '/';
    navigate('/');
  };

  return (
    <PopupWrapper visible={visible}>
      <PopupContainer>
        <DescriptionContent
          dangerouslySetInnerHTML={{
            __html: Content.productOutOfStock(productName),
          }}
        />

        <Button
          gtmConfig={Content.gtmHomeButton(code)}
          onClick={() => onClose()}
          text={Content.returnHome}
          width="100%"
        />
      </PopupContainer>
    </PopupWrapper>
  );
};

OutOfStockPopup.propTypes = {
  productName: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

export default OutOfStockPopup;
