import {
  crewLogin,
  fetchBanners,
  fetchProductData,
  fetchProductsList,
  fetchProductInLocalStock,
} from 'services/cloud.service';

import store from 'redux/store';

import { addProductData } from 'redux/product/actions';
import { addProductsData } from 'redux/products/actions';
import { getUserInfo, getUserDeals } from './_mcd-connector';
import { addUserData, addDealsData } from 'redux/user/actions';

export const checkUserData = async (devSettings) => {
  try {
    const userInfo = await getUserInfo(devSettings);
    store.dispatch(addUserData(userInfo));
  } catch (error) {
    console.info('Error checking user data: ', error);
    throw error;
  }
};

export const getDealsPoints = async (devSettings) => {
  try {
    const userDeals = await getUserDeals(devSettings);
    store.dispatch(addDealsData({ points: userDeals }));
  } catch (error) {
    console.info('Error fetching user deals: ', error);
    throw error;
  }
};

export const getInLocalStock = async (code) => {
  try {
    const inStock = await fetchProductInLocalStock(code);
    return inStock;
  } catch (error) {
    console.info('Error fetching product local in-stock: ', error);
    throw error;
  }
};

export const getProductData = async (code) => {
  try {
    const product = await fetchProductData(code);
    store.dispatch(addProductData(product));
  } catch (error) {
    console.info('Error fetching product: ', error);
    throw error;
  }
};

const categoryList = {
  crew: 'crew',
  family: 'family',
};

export const getProductsData = async (isCrewUser, familyMode) => {
  try {
    let categories = [];
    if (isCrewUser) categories.push(categoryList.crew);
    if (familyMode) categories.push(categoryList.family);

    const list = await fetchProductsList({ categories });

    const inStockProducts = list
      .filter(({ freeStock }) => Boolean(freeStock))
      .map(
        ({
          code,
          name,
          image,
          // largeImage,
          imagesArray,
          linkFavorite,
          price,
          description,
          freeStock,
          prio,
          isCrew,
          limited,
          productType,
          externalLink,
        }) => ({
          code,
          name,
          image,
          // largeImage,
          imagesArray,
          linkFavorite,
          price,
          description,
          inStock: freeStock,
          prio,
          isCrew,
          limited,
          productType,
          externalLink,
        })
      )
      .sort((x, y) => x.prio - y.prio);

    const outStockProducts = list
      .filter(({ freeStock }) => freeStock <= 0)
      .map(
        ({
          code,
          name,
          image,
          largeImage,
          linkFavorite,
          price,
          description,
          freeStock,
          prio,
          isCrew,
          limited,
          productType,
          externalLink,
        }) => ({
          code,
          name,
          image,
          largeImage,
          linkFavorite,
          price,
          description,
          inStock: freeStock,
          prio,
          isCrew,
          limited,
          productType,
          externalLink,
        })
      )
      .sort((x, y) => x.prio - y.prio);

    const products = [...inStockProducts, ...outStockProducts];

    store.dispatch(addProductsData(products));
  } catch (error) {
    console.info('Error fetching products: ', error);
    throw error;
  }
};

export const checkCrewLogin = async (code) => {
  try {
    return await crewLogin(code);
  } catch (error) {
    console.info('Error fetching crew code: ', error);
    throw error;
  }
};

export const getBanners = async (isCrewUser, familyMode) => {
  try {
    let categories = [];
    if (isCrewUser) categories.push(categoryList.crew);
    if (familyMode) categories.push(categoryList.family);

    return await fetchBanners({ categories });
  } catch (error) {
    console.info('Error fetching banners: ', error);
    throw error;
  }
};
