import React from 'react';
import PropTypes from 'prop-types';

import { Content } from 'components/Form';
import FieldSelect from './SelectField.styles';
import { FieldContainer, FieldLabel, FieldError } from '../InputField/InputField.styles';

const SelectField = ({ name, placeholder, register, error, width }) => (
  <FieldContainer width={width}>
    <FieldLabel htmlFor={name}>
      {Content[name]}
    </FieldLabel>

    <FieldSelect id={name} width={width} {...register}>
      <option value="">{placeholder}</option>
      <option value="S">S</option>
      <option value="M">M</option>
    </FieldSelect>

    {error && <FieldError>{Content[`${name}Error`]}</FieldError>}
  </FieldContainer>
);

SelectField.propTypes = {
  register: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  width: PropTypes.number,
  error: PropTypes.bool
};

SelectField.defaultProps = {
  placeholder: '',
  error: false,
  width: 100
};

export default SelectField;
