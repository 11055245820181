import styled, { createGlobalStyle, css } from 'styled-components';

export default createGlobalStyle(({ theme, isFamily }) => {
  const { colors, fontFamily, fontWeight, typography, spacings } = theme;

  return css`
    #root {
      height: 100%;
    }

    * {
      box-sizing: border-box;
    }

    html, body {
      -webkit-tap-highlight-color: transparent;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      -webkit-text-size-adjust: 100%;
      -webkit-touch-callout: none;
      -ms-overflow-style: none;

      overflow-x: hidden;
      user-select: none;

      height: 100%;
      width: 100%;

      padding: 0;
      margin: 0;
    }

    body {
      background-color: ${isFamily ? colors.red : colors.yellow};
      font: ${typography.t16} / 1.5 ${fontFamily.base};
      color: ${colors.lightBlack};
    }

    h1, h2, h3 {
      line-height: 1.25;
      margin: 0 0 ${spacings.sp12};
    }

    h1 {
      font-size: ${typography.t18};
    }

    h2 {
      font-size: ${typography.t16};
    }

    h3 {
      font-size: ${typography.t14};
      margin-bottom: ${spacings.sp8};
    }

    p {
      margin: 0 0 ${spacings.sp8};
      line-height: 1.5;
    }

    a {
      text-decoration: none;

      &:hover, &:active {
        outline: none;
      }
    }

    b, strong {
      font-weight: ${fontWeight.bold};
    }

    button {
      border: none;
      outline: none;
      cursor: pointer;
    }

    img, svg {
      max-width: 100%;
      vertical-align: top;
    }

    code {
      font-family:
        source-code-pro, Menlo, Monaco,
        Consolas, 'Courier New', monospace;
    }
  `;
});

export const AppWrapper = styled.main`
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  display: block;
`;

export const AppLayout = styled.div`
  min-height: 100%;
  width: 100%;
`;

export const PageWrapper = styled.div`
  justify-content: center;
  flex-direction: column;
  align-items: center;

  position: relative;
  max-width: 768px;
  margin: 0 auto;

  display: flex;
  height: 100%;
  width: 100%;
`;

export const AvatarImg = styled.img`
    pointer-events: none;
    position: absolute;
    z-index: 6;
    top: 0;
`;
