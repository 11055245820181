import React from 'react';
import PropTypes from 'prop-types';
import Text from './GradientText.styles';

const GradientText = ({ tag, size, text, isFamily }) => (
  <Text as={tag} size={size} isFamily={isFamily}>
    {text}
  </Text>
);

GradientText.propTypes = {
  text: PropTypes.string.isRequired,
  isFamily: PropTypes.bool,
  size: PropTypes.number,
  tag: PropTypes.string,
};

GradientText.defaultProps = {
  isFamily: false,
  size: 18.978,
  tag: 'span',
};

export default GradientText;
