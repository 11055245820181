import styled from 'styled-components';
import { InputField, InputPlaceholder } from '../InputField/InputField.styles';

export default styled.div`
  position: relative;
  display: block;
  width: 100%;

  .datePicker {
    ${InputField()}

    ::placeholder {
      ${InputPlaceholder()}
    }
  }
`;
