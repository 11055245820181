import styled, { css } from 'styled-components';

export default styled.button(
  ({ theme, green, disabled, purple }) => css`
    padding: ${theme.spacings.sp8} ${theme.spacings.sp4} ${theme.spacings.sp10};
    background-color: ${theme.colors.paleOrange};
    border: 2px solid ${theme.colors.white};
    border-radius: ${theme.spacings.sp16};

    font-weight: ${theme.fontWeight.bold};
    margin: ${theme.spacings.sp8} auto 0;
    font-size: ${theme.typography.t11};

    width: clamp(108px, 25.6vw, 117px);
    height: clamp(32px, 8.8vw, 39px);

    color: ${theme.colors.white};
    line-height: normal;

    position: relative;
    display: block;
    outline: none;

    ${green &&
    css`
      background-color: ${theme.colors.green};
    `}
    ${purple &&
    css`
      background-color: ${theme.colors.purple};
    `}
    ${disabled &&
    css`
      background-color: ${theme.colors.silvery};
      color: ${theme.colors.smokeGrey};
    `}
  `
);
