import { initializeApp, getApps, getApp } from 'firebase/app';
/* import {
  initializeAppCheck,
  ReCaptchaV3Provider,
  getToken,
} from 'firebase/app-check'; */
import { getAuth, signInAnonymously } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import 'firebase/compat/firestore';

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
} = process.env;

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);

// const RE_CAPTCHA_V3_KEY_STAGING = '6LfA4XgeAAAAAEtaA9ZHppQw3dyjAMH2GUnsKpp8';

// const RE_CAPTCHA_V3_KEY_PRODUCTION = '6LdPQt0eAAAAAJUUuoQrfVy-UWTPrPrUVmd7CpcV';

if (location.hostname === 'localhost') {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = '1D707BC5-AB52-47F3-8C37-D66393666C09';
}

/* const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(
    process.env.REACT_APP_ENV === 'staging'
      ? RE_CAPTCHA_V3_KEY_STAGING
      : RE_CAPTCHA_V3_KEY_PRODUCTION
  ),

  isTokenAutoRefreshEnabled: true,
}); */

// Check if we have initialized an app
const firebaseApp = getApps().length === 0 ? app : getApp();

const auth = getAuth(firebaseApp);

export const initializeFB = () => {
  return new Promise(() => {
    signInAnonymously(auth)
      .then(() => {
        console.info('Anonymous sign-in method:', auth);
        console.info('Auth:', auth.app);
        console.info('firebase:', firebaseApp);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(
          'Error with auth! Code: %s, Message: %s',
          errorCode,
          errorMessage
        );
      });
  });
};

/* export const initWebAppCheck = async () => {
  let appCheckTokenResponse;
  try {
    appCheckTokenResponse = await getToken(appCheck, false);
    return appCheckTokenResponse.token;
  } catch (error) {
    // Handle Error Here
    console.info(error.message);
  }
}; */

// Initialize cloud functions
export const functions = getFunctions(app, 'europe-west3');

export const instance = firebaseApp;
