import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';

import LoaderContainer from './Loader.styles';
import lottieService from 'services/lottie.service';
import LoaderData from 'assets/animations/loader.json';
import { getIsFamilyUser } from '../../../redux/user/selectors';
import FamilyLoaderData from 'assets/animations/family-loader.json';

const Loader = ({ isOverlay, isFamilyUser }) => {
  const loaderContainer = useRef(null);

  useEffect(() => {
    lottieService({
      container: loaderContainer.current,
      animationData: isFamilyUser ? FamilyLoaderData : LoaderData,
      autoplay: true,
      loop: true
    });

    return () => lottie.destroy();
  }, [isFamilyUser]);

  return (
    <LoaderContainer
      isOverlay={isOverlay}
      ref={loaderContainer}
    />
  );
};

Loader.propTypes = {
  isOverlay: PropTypes.bool,
  isFamilyUser: PropTypes.bool.isRequired,
};

Loader.defaultProps = {
  isOverlay: false
};

const mapStateToProps = (state) => ({
  isFamilyUser: getIsFamilyUser(state),
});

export default  connect(mapStateToProps)(Loader);
