import {
  ADD_PRODUCTS_DATA,
  ADD_PRODUCTS_DATA_FAILED,
  SET_FILTER_TYPE,
  SET_FILTER_TYPE_FAILED,
  ADD_FILTERED_PRODUCTS,
  ADD_FILTERED_PRODUCTS_FAILED,
} from './actions';

export const initialState = {
  error: '',
  list: [],
  filteredList: [],
  filterType: 0, // 0 - products and tickets, 1 - only products, 2 - only tickets
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_PRODUCTS_DATA:
      return { ...state, list: [...action.data] };

    case ADD_PRODUCTS_DATA_FAILED:
      return { ...state, error: action.error };

    case SET_FILTER_TYPE:
      return { ...state, filterType: action.data };

    case SET_FILTER_TYPE_FAILED:
      return { ...state, error: action.error };

    case ADD_FILTERED_PRODUCTS:
      return { ...state, filteredList: [...action.data] };

    case ADD_FILTERED_PRODUCTS_FAILED:
      return { ...state, error: action.error };

    default:
      return state;
  }
};
