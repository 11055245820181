import { useEffect, useState } from 'react';
import FontFaceObserver from 'fontfaceobserver';

const useFonts = () => {
  const [isFontsLoading, setIsFontsLoading] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line camelcase
    const speedee_bold = new FontFaceObserver('speedee', { weight: 600 });
    // eslint-disable-next-line camelcase
    const speedee_cd_reg = new FontFaceObserver('speedee_cd', { weight: 400 });
    // eslint-disable-next-line camelcase
    const speedee_cd_bold = new FontFaceObserver('speedee_cd', { weight: 600 });

    Promise.all([
      speedee_bold.load(),
      speedee_cd_reg.load(),
      speedee_cd_bold.load()
    ]).then(
      () => setIsFontsLoading(true),
      () => setIsFontsLoading(false)
    );
  });

  return isFontsLoading;
};

export default useFonts;
