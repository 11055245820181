import styled, { css } from 'styled-components';

export const ProductsWrapper = styled.div`
  padding-bottom: max(3.2vh, 26px);
  position: relative;

  min-height: 100vh;
  max-width: 425px;

  margin: 0 auto;
  display: block;
`;

// "margin-inline" is not supported on Safari < 14.1
export const ContentWrapper = styled.div`
  margin-right: max(6.667vw, 25px);
  margin-left: max(6.667vw, 25px);

  margin-top: -250px;
  position: relative;
  max-width: 350px;

  display: block;
  z-index: 1;

  @media (max-width: 350px) {
    margin-top: -200px;
  }
`;

export const TextWrapper = styled.p(({ theme, isFamily }) => css`
  color: ${isFamily ? theme.colors.white : theme.colors.lightBlack};
  margin-bottom: ${theme.spacings.sp30};
  line-height: ${theme.typography.t26};
  font-size: ${theme.typography.t18};

  letter-spacing: normal;
  position: relative;
  display: block;
  width: 100%;

  a {
      font-weight: ${theme.fontWeight.regular};
      line-height: ${theme.typography.t24};
      font-size: ${theme.typography.t16};
      color: ${theme.colors.white};
      text-decoration: underline;
  }
`);

export const UserData = styled.span(({ theme }) => css`
  margin-bottom: ${theme.spacings.sp16};
  align-items: flex-end;
  position: relative;

  display: flex;
  width: 100%;

  span {
    flex-basis: 65%;
  }

  strong {
    font-size: ${theme.typography.t24};
  }
`);

export const ProductsContainer = styled.div(({ theme }) => css`
  grid-gap: max(${theme.spacings.sp12}, 3.2vw);
  grid-template-columns: repeat(2, 1fr);

  grid-template-rows: auto;
  grid-auto-rows: auto;

  position: relative;
  display: grid;
`);

export const FiltersButtonsBox = styled.div(({ theme }) => css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${theme.spacings.sp30};
`);

