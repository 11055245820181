import { useLocation } from 'react-router-dom';

const useCodeParam = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  return searchParams.get('code');
};

export default useCodeParam;
