import styled, { css } from 'styled-components';

export default styled.button(({ theme, active, disabled }) => css`
  background-color: transparent;
  color: ${theme.colors.white};
  transition: all 0.2s;
  
  font-family: ${theme.fontFamily.base};
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.typography.t11};
  line-height: 1;
  letter-spacing: normal;
  
  padding: ${theme.spacings.sp8} ${theme.spacings.sp4};
  border: 1px solid ${theme.colors.white};
  border-radius: ${theme.spacings.sp16};
  text-align: center;

  position: relative;
  display: block;
  width: 48%;
  height: clamp(32px, 8.8vw, 39px);

  cursor: pointer;
  margin: 0;

  ${active && css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
  `}

  ${disabled && css`
    pointer-events: none;
  `}
`);
