import React from 'react';
import PropTypes from 'prop-types';

import LabelContainer from './LogoLabel.styles';
import { LandingLogo, FormLogo } from 'assets/images';

const LogoLabel = ({ rewards }) => (
  <LabelContainer>
    <img
      alt={rewards ? 'My Rewards' : 'My M'}
      src={rewards ? LandingLogo : FormLogo}
    />
  </LabelContainer>
);

LogoLabel.propTypes = {
  rewards: PropTypes.bool,
};

LogoLabel.defaultProps = {
  rewards: false,
};

export default LogoLabel;
