import {
  ADD_PRODUCT_DATA,
  ADD_PRODUCT_DATA_FAILED,
  ADD_PRODUCT_ORDERID,
  ADD_PRODUCT_ORDERID_FAILED,
} from './actions';

export const initialState = null;

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_PRODUCT_DATA:
      return { ...state, ...action.data };

    case ADD_PRODUCT_DATA_FAILED:
      return { ...state, error: action.error };

    case ADD_PRODUCT_ORDERID:
      return { ...state, ...action.data };

    case ADD_PRODUCT_ORDERID_FAILED:
      return { ...state, error: action.error };

    default:
      return state;
  }
};
