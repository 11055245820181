export default {
  login: 'Inloggen',

  description:
    "Log in met je McDonald's account om alle collector's items in <strong>de MyM Fanshop</strong> te ontdekken. Nog geen account? Maak er vliegensvlug een aan!",

  gtmMount: {
    event: 'page_view',
    eventProperties: {
      page_virtual_path: '/rewardshop/inlog',
      page_type: 'rewardshop',
    },
  },

  gtmButton: {
    eventLabel: '/rewardshop/overview',
    eventAction: 'Inloggen',
    event: 'button_click',
  },
};
