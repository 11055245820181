import styled, { css } from 'styled-components';

export default styled.div(({ theme, isFamily }) => css`
  margin: ${theme.spacings.sp30} auto 0;
  text-align: center;
  position: relative;

  max-width: 350px;
  display: block;
  width: 100%;

  span {
    font-weight: ${theme.fontWeight.regular};
    line-height: ${theme.typography.t24};
    font-size: ${theme.typography.t14};
    color: ${isFamily ? theme.colors.white : theme.colors.lightBlack};
  }
    
  a {
    font-weight: ${theme.fontWeight.regular};
    line-height: ${theme.typography.t24};
    font-size: ${theme.typography.t16};
    color: ${theme.colors.white};
    text-decoration: underline;
  }  
`);
