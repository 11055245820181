import styled, { css } from 'styled-components';

export default styled.span(({ theme, size, isFamily }) => css`
  background-image: ${isFamily ? 'none' : theme.colors.orangeToPink };
  font-family: ${theme.fontFamily.base};
  font-weight: ${theme.fontWeight.bold};

  ${!isFamily && (css`
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
      text-fill-color: transparent;
  `)}

  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;

  font-size: min(${size}vw, 5rem);
  background-color: transparent;
  backface-visibility: hidden;

  letter-spacing: normal;
  background-size: 100%;

  line-height: normal;
  position: relative;

  color: ${isFamily ? theme.colors.yellow : 'transparent'};
  display: initial;

  padding: 0;
  margin: 0;
`);
