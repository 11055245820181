import styled, { css, keyframes } from 'styled-components';

const show = (width, x = -22.9) => keyframes`
  0% {
    transform: translateX(${x}%) scale(0);
    max-width: ${width}px;
  }

  50% {
    transform: translateX(${x}%) scale(1);
    animation-timing-function: ease-out;
    max-width: ${width}px;
  }

  75% {
    transform: translateX(${x}%) scale(1);
    animation-timing-function: ease-out;
    max-width: ${width}px;
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateX(0) scale(1);
    max-width: 120px;
  }
`;

export default styled.div(
  ({ theme, animate }) => css`
    background-color: ${theme.colors.yellow};
    border: 4px solid ${theme.colors.white};

    transform: translateX(-22.9%) scale(0);
    padding: ${theme.spacings.sp4} 0 0;
    will-change: transform, max-width;

    backface-visibility: hidden;
    transform-origin: 50% 50%;

    border-radius: 32.5px;
    position: absolute;

    overflow: hidden;
    height: 17.334vw;

    max-height: 65px;
    max-width: 120px;

    display: table;
    margin: auto;

    width: 32vw;
    right: 16vw;

    z-index: 1;
    top: 30vw;

    span {
      transition: transform 0.25s ease-out 2.75s;
      font-weight: ${theme.fontWeight.bold};
      font-size: ${theme.typography.t24};
      color: ${theme.colors.white};

      backface-visibility: hidden;
      transform: translateY(75%);
      transform-origin: 50% 50%;

      text-align: center;
      position: absolute;

      display: block;
      margin: auto 0;
      width: 100%;

      top: 55%;
      right: 0;
      left: 0;
    }

    @media (min-width: 390px) {
      right: min(20vw, 90px);
      top: min(16vh, 190px);
    }

    ${animate &&
    css`
      animation: ${show(65)} 1.5s ease-out 1.25s forwards;

      @media (max-width: 350px) {
        animation-name: ${show(55, 0)};
        max-height: 55px;

        span {
          font-size: ${theme.typography.t20};
        }
      }

      span {
        transform: translateY(-50%);
      }
    `}
  `
);
