import styled, { css } from 'styled-components';
import { typography, colors } from 'theme/theme';

export const InputField = (disabled) => css`
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  background-color: ${colors.darkWhite};
  border: 1px solid ${colors.border};

  line-height: ${typography.t15};
  font-size: ${typography.t16};
  color: ${colors.lightBlack};

  border-radius: 5px;
  position: relative;
  letter-spacing: 0;
  appearance: none;

  display: block;
  padding: 15px;

  outline: none;
  height: 50px;
  width: 100%;

  ${disabled && css`
    background-color: ${colors.lightGrey};
    color: ${colors.grey};
    pointer-events: none;
  `}

  &:focus {
    border-color: ${colors.lightBlack};
    background-color: ${colors.white};
    caret-color: ${colors.black};
    color: ${colors.black};
  }
`;

export const InputPlaceholder = () => css`
  line-height: ${typography.t15};
  font-size: ${typography.t16};
  color: ${colors.black};
  opacity: 0.5;
`;

export const FieldContainer = styled.div(({ theme, width, last }) => css`
  margin-bottom: ${theme.spacings.sp16};
  position: relative;

  width: ${width}%;
  display: block;

  ${last && css`
    margin-bottom: 0;
  `}
`);

export const FieldLabel = styled.label(({ theme }) => css`
  line-height: ${theme.typography.t24};
  font-size: 0.8125rem;
  margin-bottom: 3px;

  position: relative;
  display: block;
  width: 100%;
`);

export const FieldInput = styled.input(({ disabled }) => css`
  ${InputField(disabled)}

  ::placeholder {
    ${InputPlaceholder()}
  }
`);

export const FieldError = styled.span(({ theme, margin = 5 }) => css`
  line-height: ${theme.typography.t16};
  font-size: ${theme.typography.t14};
  color: ${theme.colors.error};

  margin-top: ${margin}px;
  position: relative;

  display: block;
  width: 100%;
`);
