import React from 'react';
import PropTypes from 'prop-types';

import { pushEvent } from 'hooks';
import ButtonStyle from './Button.styles';

const Button = ({
  gtmConfig,
  children,
  disabled,
  onClick,
  yellow,
  width,
  text,
  link,
  ...props
}) => {
  const onButtonClick = event => {
    gtmConfig && pushEvent(gtmConfig);
    link ? window.location.href = link : onClick(event);
  };

  return (
    <ButtonStyle
      onClick={onButtonClick}
      disabled={disabled}
      yellow={yellow}
      width={width}
      {...props}
    >
      {text || children}
    </ButtonStyle>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.node.isRequired
  ]),

  gtmConfig: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,

  width: PropTypes.string,
  yellow: PropTypes.bool,

  link: PropTypes.string,
  text: PropTypes.string,
};

Button.defaultProps = {
  onClick: () => void 0,
  gtmConfig: undefined,

  disabled: false,
  children: null,
  width: '250px',
  yellow: false,

  link: null,
  text: '',
};

export default Button;
