import styled, { css } from 'styled-components';

export const PopupWrapper = styled.aside(({ visible }) => css`
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.25s 0.5s;

  position: fixed;
  display: block;

  z-index: 10;
  opacity: 0;

  padding: 0;
  margin: 0;

  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  ${visible && css`
    transition-delay: 0s;
    opacity: 1;

    > div {
      transition-timing-function: ease-out;
      transform: translateY(-50%);
      transition-delay: 0.25s;
      opacity: 1;
    }
  `}
`);

export const PopupContainer = styled.div(({ theme }) => css`
  transition: transform 0.5s ease-in, opacity 0.5s;
  padding: min(3.94vh, 32px) min(9.334vw, 35px);
  background-color: ${theme.colors.white};

  backface-visibility: hidden;
  transform: translateY(-25%);

  border-radius: 22px;
  position: absolute;
  max-width: 350px;

  display: block;
  width: 86.4vw;

  margin: auto;
  z-index: 10;
  opacity: 0;

  top: 50%;
  right: 0;
  left: 0;
`);

export const DescriptionContent = styled.p(({ theme }) => css`
  line-height: ${theme.typography.t26};
  font-size: ${theme.typography.t18};

  color: ${theme.colors.lightBlack};
  letter-spacing: normal;

  text-align: center;
  margin: 0 0 20px;
  display: block;

  width: 100%;
  padding: 0;
`);
