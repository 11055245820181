import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserEmail, getDealsData, getIsFamilyUser } from 'redux/user/selectors';

import { initializeFB /*, initWebAppCheck */ } from 'lib/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { checkUserData, getDealsPoints } from 'utils/_app-helpers';
import { tagsToPlexure } from 'utils/_mcd-connector';

import { useLoader, useFonts, useImages } from 'hooks';
import { AppLayout, AppWrapper } from 'theme/globalStyles';

import { Login, Products, Product, Form, Thanks } from 'routes';
import * as images from 'assets/images';
import store from './redux/store';
import { setIsFamilyUser } from './redux/user/actions';
import { GlobalStyle } from './theme';

const App = ({ email, deals, isFamilyUser }) => {
  const search = new URLSearchParams(useLocation().search);
  const familyMode = search.has('family') || false;

  const auth = getAuth();
  const navigate = useNavigate();

  const isFontsLoading = useFonts();
  const isImagesLoaded = useImages(images);

  const [isAppLoading, setIsAppLoading] = useState(true); // useState(false);
  const loader = useLoader(isAppLoading || !isFontsLoading || isImagesLoaded);

  const loginRedirect = useCallback(() => {
    setIsAppLoading(false);
    navigate('/login');
  }, [navigate]);

  const handleAuth = useCallback(
    (isDev) => {
      onAuthStateChanged(auth, async (user) => {
        // const appCheckToken = await initWebAppCheck();

        if (user /* && appCheckToken */) {
          try {
            const hasDeals = typeof deals === 'number';

            if (!auth.currentUser) {
              return loginRedirect();
            }

            if (!email || !hasDeals) {
              // setIsAppLoading(true);
              await checkUserData(isDev);

              await getDealsPoints(isDev);
              setIsAppLoading(false);
            }
          } catch {
            loginRedirect();
          }
        } else {
          await initializeFB();
        }
      });
    },
    [auth, email, deals, loginRedirect]
  );

  useEffect(() => {
    const isMCDDefined = typeof mcd !== 'undefined';
    const isDevSettings = process.env.NODE_ENV === 'development';
    const isGTMSettings = window.location.href.indexOf('gtm-desktop') > -1;

    document.addEventListener('mcdBridgeReady', () => {
      handleAuth();

      tagsToPlexure(['MKT_DISCRETIONARY_50']);
    });

    if (isMCDDefined && !isDevSettings && !isGTMSettings) {
      return handleAuth();
    }

    if (isDevSettings || isGTMSettings) {
      handleAuth(true);
    }
  }, [handleAuth]);

  useEffect(() => {
    if (familyMode) {
      store.dispatch(setIsFamilyUser(true));
    }
  }, [familyMode]);

  return (
    <AppWrapper>
      <GlobalStyle isFamily={isFamilyUser} />

      <AppLayout>
        {loader || (
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/" element={<Products />} />
            <Route exact path="/product" element={<Product />} />
            <Route exact path="/form" element={<Form />} />
            <Route exact path="/thanks" element={<Thanks />} />
          </Routes>
        )}
      </AppLayout>
    </AppWrapper>
  );
};

App.propTypes = {
  isFamilyUser: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  deals: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  isFamilyUser: getIsFamilyUser(state),
  email: getUserEmail(state),
  deals: getDealsData(state),
});

export default connect(mapStateToProps)(App);
