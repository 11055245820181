import { ProductDescription, Fields, ConfirmationPopup } from 'components/Form';
import { createOrder, placeOrder } from 'services/cloud.service';
import { LogoLabel, ProductAnimation } from 'components/shared';

import React, { useState, useCallback, useEffect } from 'react';
import OutOfStockPopup from 'components/shared/OutOfStockPopup';
import { getIsFamilyUser } from '../../redux/user/selectors';

import { FormWrapper, ContentWrapper } from './Form.styles';
import { getProductState } from 'redux/product/selectors';
import { addProductOrderId } from 'redux/product/actions';
import { useLoader, useOnMount, pushEvent } from 'hooks';
import { getInLocalStock } from 'utils/_app-helpers';
import { AvatarImg } from '../../theme/globalStyles';
import { getUserState } from 'redux/user/selectors';

import { burnPoints } from 'utils/_mcd-connector';
import { useNavigate } from 'react-router-dom';
import { FamilyBG } from '../../assets/images';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from 'redux/store';

const Form = ({ user, product, isFamilyUser }) => {
  const navigate = useNavigate();

  const [visiblePopup, setVisiblePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [inStock, setInStock] = useState(true);

  const loader = useLoader(isLoading, true);
  const loaderRedirect = useLoader(
    user?.isProductOrdered || user?.points < product?.price,
    true
  );
  const isDevSettings = process.env.NODE_ENV === 'development';

  const checkProductInLocalStock = useCallback(async () => {
    const inStock = await getInLocalStock(product.code);
    setInStock(inStock);
  }, [product.code, setInStock]);

  const onSubmit = useCallback(
    (data) => {
      const { firstName, lastName, houseNumber, postalCode } = data;

      pushEvent({
        eventLabel: `/rewardshop/confirm/${product.code}`,
        eventAction: 'Versturen',
        event: 'button_click',
      });

      setFormData({
        termsAcknowledged: data.terms,
        isCrew: user.isCrewUser,
        street: data.streetName,
        isFamily: isFamilyUser,
        extension: data.suffix,

        code: product.code,
        email: user.email,
        city: data.venue,

        houseNumber,
        postalCode,
        firstName,
        lastName,
      });

      setVisiblePopup(true);

      pushEvent({
        event: 'page_view',
        eventProperties: {
          page_virtual_path: `/rewardshop/confirm/${product.code}`,
          page_type: 'rewardshop',
        },
      });
    },
    [user.email, product.code, user.isCrewUser, isFamilyUser]
  );

  const onCreate = useCallback(
    (orderId) => {
      pushEvent({ ecommerce: null });

      pushEvent({
        event: 'purchase',
        ecommerce: {
          total_points: product.price,
          affiliation: 'Reward Shop',
          transaction_id: orderId,
          currency: 'EUR',
          value: 0,

          items: [
            {
              item_id: product.code,
              item_name: product.name,
              item_category: 'Apparel',
              item_variant: 'regular',
              points: product.price,
              item_brand: 'McD',
              quantity: 1,
              price: 0,
            },
          ],
        },
      });

      navigate('/thanks');
    },
    [product, navigate]
  );

  const onConfirm = useCallback(async () => {
    setVisiblePopup(false);
    setIsLoading(true);

    const response = await createOrder(formData);

    // Check if response is out-of-stock error.
    if (response instanceof Error && response.message === 'out-of-stock') {
      setInStock(false);
      setIsLoading(false);
      return;
    }

    const sendPoints = await burnPoints(isDevSettings, product.price);
    const placeOrderToAPI = await placeOrder({ orderId: response });

    // Check if response is out-of-stock error from API
    if (
      !placeOrderToAPI?.data?.orderConfirmed &&
      !placeOrderToAPI?.data?.orderPlaced
    ) {
      setInStock(false);
      setIsLoading(false);
      return;
    }

    response &&
      sendPoints &&
      store.dispatch(addProductOrderId({ orderId: response }));

    placeOrderToAPI && setIsLoading(false);
    response instanceof Error ? console.error(response) : onCreate(response);
  }, [formData, isDevSettings, product.price, onCreate]);

  useOnMount({
    event: 'page_view',
    eventProperties: {
      page_virtual_path: `/rewardshop/form/${product.code}`,
      page_type: 'rewardshop',
    },
  });

  useEffect(() => {
    checkProductInLocalStock(product, setInStock);
  }, [product, setInStock, checkProductInLocalStock]);

  useEffect(() => {
    if (user?.isProductOrdered || user?.points < product?.price) {
      navigate('/', {
        replace: true,
      });
    }
  }, [user, product, navigate]);

  return (
    loaderRedirect || (
      <FormWrapper isFamily={isFamilyUser}>
        <LogoLabel />

        {isFamilyUser && <AvatarImg src={FamilyBG} />}

        {product.image && (
          <ProductAnimation
            // largeImage={product.largeImage}
            image={product.image}
            top={-50}
          />
        )}

        <ContentWrapper>
          <ProductDescription name={product.name} />

          <Fields
            firstName={user.firstname}
            lastName={user.lastname}
            popup={visiblePopup}
            onSubmit={onSubmit}
          />
        </ContentWrapper>

        {visiblePopup && (
          <ConfirmationPopup
            onCancel={() => setVisiblePopup(false)}
            productName={product.name}
            userPoints={user.points}
            onConfirm={onConfirm}
            price={product.price}
            userInfo={formData}
            code={product.code}
          />
        )}

        {!inStock && (
          <OutOfStockPopup productName={product.name} code={product.code} />
        )}

        {loader}
      </FormWrapper>
    )
  );
};

Form.propTypes = {
  isFamilyUser: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isFamilyUser: getIsFamilyUser(state),
  product: getProductState(state),
  user: getUserState(state),
});

export default connect(mapStateToProps)(Form);
