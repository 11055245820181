import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getDealsData } from 'redux/user/selectors';
import { getProductData, getInLocalStock } from 'utils/_app-helpers';
import { getProductState } from 'redux/product/selectors';

import { LogoLabel, ProductAnimation, PointsLabel } from 'components/shared';
import { ProductDescription, FAQButton } from 'components/Product';
import OutOfStockPopup from 'components/shared/OutOfStockPopup';
import { useCodeParam, useLoader, pushEvent } from 'hooks';

import ProductWrapper from './Product.styles';
import { AvatarImg } from '../../theme/globalStyles';
import { FamilyBG } from '../../assets/images';
import { getIsFamilyUser } from '../../redux/user/selectors';

const Product = ({ deals, product, isFamilyUser }) => {
  const abortController = useMemo(() => new AbortController(), []);
  const [isLoading, setIsLoading] = useState(!product);
  const [inStock, setInStock] = useState(true);

  const loader = useLoader(isLoading);
  const productCode = useCodeParam();

  const fetchProductData = useCallback(async () => {
    await getProductData(productCode);
    const { aborted } = abortController.signal;
    !aborted && setIsLoading(false);
  }, [productCode, abortController.signal]);

  const checkProductInLocalStock = useCallback(async () => {
    const inStock = await getInLocalStock(productCode);
    setInStock(inStock);
  }, [productCode, setInStock]);

  useEffect(() => {
    const body = document.querySelector('#root');

    body.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  }, []);

  useEffect(() => {
    setIsLoading(!product);
    !product && fetchProductData();
    return () => abortController.abort();
  }, [product, fetchProductData, abortController]);

  useEffect(() => {
    checkProductInLocalStock(productCode, setInStock);
  }, [productCode, setInStock, checkProductInLocalStock]);

  useEffect(() => {
    if (!product) return;
    const notEnoughPoints = deals < product.price;
    const path = `${(notEnoughPoints || '') && 'in'}sufficient`;

    pushEvent({
      event: 'page_view',
      eventProperties: {
        page_virtual_path: `/rewardshop/${path}/${productCode}`,
        page_type: 'rewardshop',
      },
    });
  }, [deals, product, productCode]);

  return (
    <>
      {loader || (
        <ProductWrapper>
          <LogoLabel rewards />

          {isFamilyUser && <AvatarImg src={FamilyBG} />}

          {product.image && (
            <ProductAnimation
              zoom={!!product.imagesArray?.length}
              // largeImage={product.largeImage}
              images={product.imagesArray}
              image={product.image}
              code={productCode}
              top={-10}
            />
          )}

          {(product.price || (!product.price && product.price === 0)) && (
            <PointsLabel points={product.price} />
          )}

          <ProductDescription
            description={product.description}
            favorite={product.linkFavorite}
            price={product.price}
            name={product.name}
            code={product.code}
            points={deals}
            productType={product.productType || 1}
            externalLink={product.externalLink}
          />

          <FAQButton />

          {!inStock && (
            <OutOfStockPopup productName={product.name} code={productCode} />
          )}
        </ProductWrapper>
      )}
    </>
  );
};

Product.propTypes = {
  deals: PropTypes.number.isRequired,

  product: PropTypes.shape({
    imagesArray: PropTypes.arrayOf(PropTypes.string),
    linkFavorite: PropTypes.string.isRequired,
    // largeImage: PropTypes.string.isRequired,

    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,

    description: PropTypes.string,
    plexure: PropTypes.string,

    image: PropTypes.string,
    price: PropTypes.number,

    productType: PropTypes.number,
    externalLink: PropTypes.string,
  }),
  isFamilyUser: PropTypes.bool.isRequired,
};

Product.defaultProps = {
  product: null,
};

const mapStateToProps = (state) => ({
  isFamilyUser: getIsFamilyUser(state),
  product: getProductState(state),
  deals: getDealsData(state),
});

export default connect(mapStateToProps)(Product);
