import styled, { css } from 'styled-components';
import { Checkmark } from 'assets/images';
import { spacings } from 'theme/theme';

const CheckboxMargin = spacings.sp12;
const CheckboxSize = '24px';

// Form Fields:
export const FormContainer = styled.form(({ theme }) => css`
  margin: 30px auto ${theme.spacings.sp30};

  position: relative;
  max-width: 375px;

  display: block;
  width: 100%;

  padding: 0;
  margin: 0;
`);

export const SectionTitle = styled.h6(({ theme }) => css`
  margin: ${theme.spacings.sp12} 0 ${theme.spacings.sp24};
  font-weight: ${theme.fontWeight.bold};
  line-height: ${theme.typography.t24};
  font-size: ${theme.typography.t16};
`);

export const FieldsContainer = styled.div`
  justify-content: space-between;
  align-content: space-between;

  align-items: baseline;
  flex-direction: row;

  flex-wrap: wrap;
  display: flex;
`;

export const Separator = styled.hr(({ theme }) => css`
  margin: ${theme.spacings.sp24} 0 ${theme.spacings.sp12};
  border: 1px solid ${theme.colors.lightYellow};
  width: 100%;
  height: 0;

  &:last-child {
    margin-bottom: 0;
  }
`);

// Terms & Conditions:
export const TermsContainer = styled.div(({ theme }) => css`
  margin: ${theme.spacings.sp12} 0 ${theme.spacings.sp16};
  align-content: center;
  position: relative;

  flex-wrap: wrap;
  display: flex;
`);

export const Checkbox = styled.input(({ theme, checked }) => css`
  border: 1px solid ${theme.colors.border};
  margin: 0 ${CheckboxMargin} 0 0;

  height: ${CheckboxSize};
  width: ${CheckboxSize};

  position: relative;
  border-radius: 5px;
  appearance: none;

  overflow: hidden;
  display: block;
  
  :after {
    background-color: ${theme.colors.darkWhite};
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    pointer-events: none;
    position: absolute;
    display: block;

    height: 100%;
    width: 100%;
    content: '';

    ${checked && css`
      background-image: url(${Checkmark});
    `}
  }
`);

export const TermsLabel = styled.label(({ theme }) => css`
  flex-basis: calc(100% - ${CheckboxSize} - ${CheckboxMargin});
  line-height: ${theme.typography.t18};
  font-size: ${theme.typography.t14};

  position: relative;
  letter-spacing: 0;
  display: block;
`);

export const TermsLink = styled.a`
  text-decoration: underline;
  display: inline-block;
  position: relative;
`;
