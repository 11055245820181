import styled from 'styled-components';

export default styled.div`
  padding-bottom: max(4.31vh, 35px);
  justify-content: center;
  flex-direction: column;

  position: relative;
  min-height: 100vh;
  max-width: 425px;

  margin: 0 auto;
  display: flex;
`;
