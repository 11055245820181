import styled, { css } from 'styled-components';

export const CardContainer = styled.div(
  ({ theme }) => css`
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: ${theme.colors.white};
    border-radius: ${theme.typography.t14};
    padding: ${theme.spacings.sp16};

    justify-content: space-between;
    flex-direction: column;
    display: flex;

    @media (max-width: 350px) {
      padding: ${theme.spacings.sp16} ${theme.spacings.sp10}};
  }
  
  &.disabled-card {
    pointer-events: none;
  }

    strong {
      font-size: ${theme.typography.t16};
      color: ${theme.colors.lightBlack};

      line-height: normal;
      margin-bottom: 2px;

      position: relative;
      display: block;
    }

    span {
      line-height: ${theme.typography.t18};
      font-size: ${theme.typography.t14};
      color: ${theme.colors.lightBlack};

      position: relative;
      display: block;
    }
  `
);

export const PreviewContainer = styled.div(
  ({ theme }) => css`
    margin: 0 auto ${theme.spacings.sp8};
    position: relative;
    display: block;

    img {
      --width: min(33.2vw, 125px);
      --left: calc(90px - var(--width));

      left: calc(var(--left) / 2);
      top: max(-3vh, -20px);
      width: var(--width);

      position: absolute;
      max-width: 125px;
      margin: 0 auto;
      z-index: 1;
    }
  `
);

export const PreviewNimbus = styled.div(
  ({ theme }) => css`
    background-image: linear-gradient(
      to bottom,
      ${theme.colors.red},
      ${theme.colors.yellow} 33%
    );

    border-radius: 50%;
    position: relative;

    display: block;
    margin: 0 auto;

    height: 90px;
    width: 90px;

    padding: 0;

    right: 0;
    left: 0;

    &::before {
      background-color: ${theme.colors.white};
      transform: scale(0.9);
      border-radius: 50%;

      position: absolute;
      display: block;

      padding: 50%;
      content: '';

      bottom: 0;
      right: 0;
      left: 0;
    }

    &::after {
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        ${theme.colors.white} 80%
      );

      position: absolute;
      display: block;

      height: 100%;
      width: 100%;

      content: '';

      bottom: 0;
      right: 0;
      left: 0;
    }
  `
);
