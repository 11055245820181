// event listener for bridge ready
/* global mcd */

export const getUserInfo = (devSettings) => {
  if (devSettings) {
    return new Promise((resolve) => {
      resolve({
        email: 'testemail@testist.com',
        deviceId: '000000',
        firstname: 'Test',
        lastname: 'Test',
      });
    });
  } else {
    let user = mcd.bridge.message('user'); // access to user bridge;
    user.send({ getuser: true, promptlogin: true }); // send command "getuser";
    return new Promise((resolve, reject) => {
      user.on('data', function (data) {
        console.info('got user info: ' + JSON.stringify(data));
        resolve(data);
      });
      user.on('error', function (error) {
        console.info('getuser error: ' + JSON.stringify(error));
        reject(4001);
      });
      user.on('done', function () {
        console.info('User info is received');
      });
    });
  }
};

export const promptLogin = () => {
  const user = mcd.bridge.message('user');
  user.send({ promptlogin: true }); // send command "promplogin"

  return new Promise((resolve, reject) => {
    user.on('data', function (data) {
      console.info('prompt login data: ' + JSON.stringify(data));
      resolve(data);
    });

    user.on('error', function (error) {
      console.info('prompt login error: ' + JSON.stringify(error));
      reject(error);
    });

    user.on('done', function () {
      console.info('prompt login done');
    });
  });
};

export const getUserDeals = (devSettings) => {
  if (devSettings) {
    return new Promise((resolve) => {
      resolve(777);
    });
  } else {
    const deals = mcd.bridge.message('deals');
    deals.send({ getPoints: true });

    return new Promise((resolve, reject) => {
      deals.on('data', (data) => {
        resolve(data.points);
      });

      deals.on('error', () => {
        reject(4001);
      });

      deals.on('done', () => {
        console.info('User deals is received');
      });
    });
  }
};

export const tagsToPlexure = (tags) => {
  if (process.env.NODE_ENV === 'development') return;

  return new Promise((resolve, reject) => {
    let user = mcd.bridge.message('user');

    user.send({ addTags: tags });
    user.on('data', function (data) {
      resolve(data);
    });
    user.on('error', function (error) {
      reject(error);
    });
    user.on('done', function () {
      // no more event will be emitted
      console.info('add/remove tags done');
    });
  });
};

/* toggle fullscreen */
export const toggleFullscreen = () => {
  const system = mcd.bridge.message('system'); // access to system bridge
  system.send({ hideCloseButton: true, fullscreen: true }); // valid options: true, false
};

// event listener for bridge ready
export const orientationScreen = () => {
  const system = mcd.bridge.message('system'); // access to system bridge
  system.send({ appOrientation: 'portrait' }); // valid options: user, portrait, landscape
};

export const burnPoints = (devSettings, qty) => {
  if (devSettings) {
    console.info(`Burn points in development environment: ${qty}`);
  } else {
    const deals = mcd.bridge.message('deals'); // access to deals bridge
    deals.send({ burnPoints: true, points: -qty }); // send command "burnPoints" and the amount of points to deduct.
    return new Promise((resolve, reject) => {
      deals.on('data', function (data) {
        console.info(`getPoints data: ${JSON.stringify(data)}`);
        resolve(data.points);
      });
      deals.on('error', function (error) {
        console.info(`getPoints error:  ${JSON.stringify(error)}`);
        reject(`getPoints error:  ${JSON.stringify(error)}`);
      });
      deals.on('done', function () {
        // No more event will be emitted
        console.info('getPoints done');
      });
    });
  }
};
