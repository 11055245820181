import lottie from 'lottie-web';

const lottieService = (data) =>
  lottie.loadAnimation({
    renderer: 'svg',
    autoplay: false,
    loop: true,
    speed: 1.0,
    ...data
  });

export default lottieService;
