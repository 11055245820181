import { createSelector } from 'reselect';

export const getProductsState = createSelector(
  [(state) => state.products],
  (productsState) => productsState
);

export const getProducts = createSelector(
  [getProductsState],
  (products) => products.list
);

export const getProductByCode = productCode => createSelector(
  [getProductsState],
  (products) => products.list.find(({ code }) =>
    code === productCode
  )
);

export const getFilteredProducts = createSelector(
  [getProductsState],
  (products) => products.filteredList
);

export const getFilterType = createSelector(
  [getProductsState],
  (products) => products.filterType
);