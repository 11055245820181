const primary = {
  white: '#ffffff',
  darkWhite: '#f7f7f7',
  lightGrey: '#e2e2e2',
  silvery: '#e7e7e7',
  smokeGrey: '#939393',
  grey: '#7f7f7f',
  lightBlack: '#292929',
  black: '#000000',

  green: '#63af3a',
  lightYellow: '#ffe49e',
  yellow: '#ffbc0d',
  gold: '#fbb900',
  paleOrange: '#ffa53d',
  orange: '#e87108',
  pink: '#900462',
  red: '#da0107',
  purple: '#910063',
};

const typeColors = {
  textColor: primary.lightBlack,
  border: primary.lightGrey,
  disabled: primary.grey,
  error: primary.red,
};

const gradients = {
  orangeToPink: `linear-gradient(
    to right,
    ${primary.orange},
    ${primary.red} 48%,
    ${primary.pink}
  )`,

  orangeToYellow: `linear-gradient(
    to right,
    #eb8b1a 45%,
    #fcb50f 74%,
    ${primary.yellow} 87%
  )`,
};

export const colors = {
  ...primary,
  ...typeColors,
  ...gradients,
};

export const fontFamily = {
  base: 'speedee, sans-serif',
  secondary: 'speedee_cd, sans-serif',
};

export const fontWeight = {
  bold: 700,
  medium: 500,
  regular: 400,
  light: 300,
};

export const typography = {
  t42: '2.625rem', // 42px
  t26: '1.625rem', // 26px
  t20: '1.25rem', // 20px
  t24: '1.5rem', // 24px
  t18: '1.125rem', // 18px
  t16: '1rem', // 16px
  t15: '0.938rem', // 15px
  t14: '0.875rem', // 14px
  t11: '0.6875rem', // 11px
};

export const spacings = {
  sp30: '1.875rem', // 30px
  sp24: '1.5rem', // 24px
  sp16: '1rem', // 16px
  sp12: '0.75rem', // 12px
  sp10: '0.625rem', // 10px
  sp8: '0.5rem', // 8px
  sp4: '0.25rem', // 4px
};
