export default {
  thanks: 'Gelukt',

  description: (name) =>
    `Jouw <strong>${name}</strong> valt binnen 2 a 3 weken op je deurmat. We hebben een bevestiging gestuurd naar:`,

  dashboard: 'Terug naar overzicht',

  gtmButton: {
    eventAction: 'Naar je dashboard',
    eventLabel: '/loyalty/home',
    event: 'button_click',
  },
};
