import React, { useRef, useState, useEffect } from 'react';
import { CloseIcon } from 'assets/images';

import { ButtonContainer, ZoomIcon } from './ZoomButton.styles';

const ZoomButton = () => {
  const [visible, setVisible] = useState(false);
  const timeoutId = useRef(null);

  useEffect(() => {
    timeoutId.current = setTimeout(() => setVisible(true));
    return () => clearTimeout(timeoutId.current);
  }, []);

  return (
    <ButtonContainer show={visible}>
      <ZoomIcon>
        <img alt="Zoom" src={CloseIcon} />
      </ZoomIcon>
    </ButtonContainer>
  );
};

export default ZoomButton;
