import styled, { css } from 'styled-components';

export const LoginWrapper = styled.div(({theme, isFamily}) => css `
  color: ${isFamily ? theme.colors.white : theme.colors.black};
  padding-bottom: max(10.5vh, 85px);
  position: relative;

  min-height: 100vh;
  max-width: 425px;

  margin: 0 auto;
  display: block;
`);

// "margin-inline" is not supported on Safari < 14.1
export const TextWrapper = styled.div(({ theme }) => css`
  margin-bottom: ${theme.spacings.sp30};
  margin-right: max(6.667vw, 25px);
  margin-left: max(6.667vw, 25px);

  position: relative;
  margin-top: -30px;
  max-width: 350px;

  display: block;
  z-index: 1;
`);

export const LoginText = styled.p(({ theme }) => css`
  line-height: ${theme.typography.t26};
  font-size: ${theme.typography.t18};
  
  letter-spacing: normal;
  position: relative;
  margin-top: 3px;
  display: block;
  width: 100%;
`);
