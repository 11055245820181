import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import LabelContainer from './PointsLabel.styles';

const PointsLabel = ({ points, animate }) => {
  const timeoutId = useRef(null);
  const [visible, setVisible] = useState(!animate);

  useEffect(() => {
    if (animate) {
      timeoutId.current = setTimeout(() => setVisible(true));
    }

    return () => clearTimeout(timeoutId.current);
  }, [animate]);

  return (
    <LabelContainer animate={visible}>
      <span>{points} ptn</span>
    </LabelContainer>
  );
};

PointsLabel.propTypes = {
  animate: PropTypes.bool,
  points: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

PointsLabel.defaultProps = {
  animate: true,
  points: 0,
};

export default PointsLabel;
