import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { Content } from 'components/Form';

import {
  FieldContainer,
  FieldLabel,
  FieldError
} from '../InputField/InputField.styles';

import FieldDate from './DateField.styles';
import 'react-datepicker/dist/react-datepicker.css';

const DateField = ({ name, placeholder, onChange, width, last, error }) => {
  const datePicker = useRef(null);
  const [date, setDate] = useState();

  const onDateChange = date => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    onChange(`${day}/${month}/${year}`);
    setDate(date);
  };

  useEffect(() => {
    if (!datePicker.current) return;
    datePicker.current.input.readOnly = true;
  }, [datePicker]);

  return (
    <FieldContainer width={width} last={last}>
      <FieldLabel htmlFor={name}>
        {Content[name]}
      </FieldLabel>
  
      <FieldDate>
        <DatePicker
          peekNextMonth
          selected={date}
          ref={datePicker}
          showYearDropdown
          showMonthDropdown
          maxDate={new Date()}
          dropdownMode="select"
          className="datePicker"
          dateFormat="dd/MM/yyyy"
          onChange={onDateChange}
          popperPlacement="bottom-end"
          placeholderText={placeholder}
        />
      </FieldDate>
  
      {error && <FieldError>{Content[`${name}Error`]}</FieldError>}
    </FieldContainer>
  );
};

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,

  onChange: PropTypes.func,
  width: PropTypes.number,
  error: PropTypes.bool,
  last: PropTypes.bool
};

DateField.defaultProps = {
  onChange: void 0,
  placeholder: '',

  error: false,
  last: false,
  width: 100
};

export default DateField;
