import styled, { css } from 'styled-components';

const BaseDescription = styled.span(({ theme }) => css`
  font-weight: ${theme.fontWeight.regular};
  line-height: ${theme.typography.t26};
  font-size: ${theme.typography.t18};
  color: ${theme.colors.lightBlack};

  display: block;
  width: 100%;

  padding: 0;
  margin: 0;
`);

// "margin-inline" is not supported on Safari < 14.1
export const DescriptionContainer = styled.section(({ theme }) => css`
  padding: min(3.94vh, 32px) min(9.334vw, 35px);
  background-color: ${theme.colors.white};

  margin-right: max(6.667vw, 25px);
  margin-left: max(6.667vw, 25px);

  border-radius: 22px;
  text-align: center;

  position: relative;
  margin-top: -85px;
  max-width: 350px;
`);

export const DescriptionText = styled(BaseDescription)(({ theme }) => css`
  margin-bottom: ${theme.spacings.sp16};
`);

export const UserPoints = styled(BaseDescription)(({ theme }) => css`
  margin-top: ${theme.spacings.sp16};
`);

export const ProductName = styled.strong(({ theme }) => css`
  margin-bottom: ${theme.spacings.sp16};
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.typography.t24};
  color: ${theme.colors.lightBlack};
  
  line-height: normal;
  display: block;

  width: 100%;
  padding: 0;
`);
