import styled, { css } from 'styled-components';

export default styled.div(({ theme }) => css`
  background-color: ${theme.colors.white};
  border-radius: 22px;
  text-align: center;

  position: absolute;
  display: block;

  height: 44px;
  width: 85px;

  margin: auto;
  padding: 0;
  z-index: 2;

  bottom: auto;
  top: 35px;
  right: 0;
  left: 0;
`);
