import React from 'react';
import PropTypes from 'prop-types';

import FilterButtonStyle from './FilterButton.styles';

const FilterButton = ({
  disabled,
  onClick,
  text,
  active,
  ...props
}) => {
  const onButtonClick = event => {
    onClick(event);
  };

  return (
    <FilterButtonStyle
      onClick={onButtonClick}
      disabled={disabled}
      active={active}
      {...props}
    >
      {text}
    </FilterButtonStyle>
  );
};

FilterButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  active: PropTypes.bool,
};

FilterButton.defaultProps = {
  onClick: () => void 0,
  disabled: false,
  text: '',
  active: false,
};

export default FilterButton;
