import styled, { css } from 'styled-components';
import { PageWrapper } from 'theme/globalStyles';

export const FormWrapper = styled(PageWrapper)(({isFamily, theme}) => css`
  padding-bottom: max(4.31vh, 35px);
  color: ${isFamily ? theme.colors.white : theme.colors.black};

  position: relative;
  min-height: 100vh;
  max-width: 425px;

  margin: 0 auto;
  display: block;
    
  a {
      color: ${isFamily ? theme.colors.white : theme.colors.black};
  }
`);

// "padding-inline" is not supported on Safari < 14.1
export const ContentWrapper = styled.div`
  padding-right: max(6.667vw, 25px);
  padding-left: max(6.667vw, 25px);

  margin-top: -100px;
  position: relative;

  display: block;
  z-index: 1;
`;
