import React from 'react';
import PropTypes from 'prop-types';

import { Text } from './ErrorMessage.styles';

function ErrorMessage({ text }) {
  return <>{text && <Text>{text}</Text>}</>;
}

ErrorMessage.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

ErrorMessage.defaultProps = {
  text: ''
};

export default ErrorMessage;
