import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from 'redux/store';

import Banners from 'components/Banners';
import Content from 'routes/Products/content';
import { FAQButton } from 'components/Product';
import { ProductCard } from 'components/Products';
import { CrewLoginPopup } from 'components/Products';
import { ProductAnimation, FilterButton } from 'components/shared';

import { getBanners, getProductsData } from 'utils/_app-helpers';
import { useLoader, useOnMount, pushEvent } from 'hooks';

import {
  getUserFirstname,
  getDealsData,
  getCrewUser,
  getIsFamilyUser,
} from 'redux/user/selectors';
import {
  getProducts,
  getFilterType,
  getFilteredProducts,
} from 'redux/products/selectors';
import { setIsProductOrdered } from '../../redux/user/actions';
import { addProductData } from 'redux/product/actions';
import { addFilteredProducts, setFilterType } from 'redux/products/actions';
import { FamilyBG } from 'assets/images';

import {
  ProductsWrapper,
  ContentWrapper,
  TextWrapper,
  ProductsContainer,
  UserData,
  FiltersButtonsBox,
} from './Products.styles';
import { AvatarImg } from '../../theme/globalStyles';

const Products = ({
  userName,
  deals,
  crewUser,
  products,
  filterType,
  filteredProducts,
  isFamilyUser,
}) => {
  const [crewLoginPopup, showCrewLoginPopup] = useState(false);
  const [bannersLoaded, setBannersLoaded] = useState(false);

  const search = new URLSearchParams(useLocation().search);
  const crewLogin = search.has('crew') || false;
  const [banners, setBanners] = useState([]);

  const loader = useLoader(
    bannersLoaded && crewUser ? false : !crewLogin && !filteredProducts.length
  );

  const navigate = useNavigate();
  useOnMount(Content.gtmMount);

  const onCardClick = (productCode) => {
    const product = products.find(({ code }) => code === productCode);

    pushEvent(Content.gtmProduct(productCode));
    store.dispatch(addProductData(product));
    navigate(`/product?code=${productCode}`);
  };

  const setActiveFilter = (type) => {
    try {
      if (
        (type === 1 && filterType === 1) ||
        (type === 2 && filterType === 2)
      ) {
        store.dispatch(setFilterType(0));
      } else {
        store.dispatch(setFilterType(type));
      }
    } catch (error) {
      console.info('Error setting filter: ', error);
      throw error;
    }
  };

  const setFilteredProducts = useCallback(() => {
    try {
      if (filterType === 2) {
        let productsList = products?.filter((i) => i?.productType === 2);
        store.dispatch(addFilteredProducts(productsList));
      } else if (filterType === 1) {
        let productsList = products?.filter((i) => i?.productType !== 2);
        store.dispatch(addFilteredProducts(productsList));
      } else {
        store.dispatch(addFilteredProducts(products));
      }
    } catch (error) {
      console.info('Error adding filtered products: ', error);
      throw error;
    }
  }, [filterType, products]);

  useEffect(() => {
    if (crewLogin && !crewUser) {
      return showCrewLoginPopup(true);
    } else if (crewUser && !crewLogin) {
      showCrewLoginPopup(false);
    }

    getBanners(crewUser, isFamilyUser)
      .then((banners) => setBanners(banners))
      .finally(() => setBannersLoaded(true));

    getProductsData(crewUser, isFamilyUser).then(() =>
      console.info('Products are updated')
    );
  }, [crewLogin, crewUser, isFamilyUser]);

  useEffect(() => {
    setFilteredProducts();
  }, [setFilteredProducts]);

  useEffect(() => {
    store.dispatch(setIsProductOrdered(false));
  }, []);

  return (
    <>
      {loader || (crewLoginPopup && <CrewLoginPopup />) || (
        <ProductsWrapper>
          <ProductAnimation top={-100} fanshop />

          {isFamilyUser && <AvatarImg src={FamilyBG} />}

          <ContentWrapper>
            <TextWrapper isFamily={isFamilyUser}>
              <UserData>
                <span
                  dangerouslySetInnerHTML={{
                    __html: Content.userInfo(userName),
                  }}
                />

                <strong>{deals}</strong>
              </UserData>
              Genoeg punten verzameld? Naast te gekke McDonald’s merchandise,
              kan je nu ook kiezen voor korting op toffe uitjes! Elke dinsdag
              vullen we de merch weer aan. Check{' '}
              <a href="https://www.mcdonalds.com/nl/nl-nl/services/app/MyMRewards.html?openOutsideMcd=true">
                hier
              </a>{' '}
              hoe je punten spaart!
            </TextWrapper>

            {!!banners.length && <Banners banners={banners} />}

            {!crewUser && (
              <FiltersButtonsBox>
                <FilterButton
                  onClick={() => setActiveFilter(1)}
                  text="Merchandise"
                  active={filterType === 1}
                />

                <FilterButton
                  onClick={() => setActiveFilter(2)}
                  text="Uitjes"
                  active={filterType === 2}
                />
              </FiltersButtonsBox>
            )}

            <ProductsContainer>
              {filteredProducts.map((product) => (
                <ProductCard
                  onClick={() => onCardClick(product.code)}
                  key={product.code}
                  {...product}
                />
              ))}
            </ProductsContainer>
          </ContentWrapper>

          <FAQButton />
        </ProductsWrapper>
      )}
    </>
  );
};

Products.propTypes = {
  userName: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  deals: PropTypes.number.isRequired,
  crewUser: PropTypes.bool.isRequired,
  filterType: PropTypes.number.isRequired,
  filteredProducts: PropTypes.array.isRequired,
  isFamilyUser: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  deals: getDealsData(state),
  crewUser: getCrewUser(state),
  products: getProducts(state),
  filterType: getFilterType(state),
  userName: getUserFirstname(state),
  isFamilyUser: getIsFamilyUser(state),
  filteredProducts: getFilteredProducts(state),
});

export default connect(mapStateToProps)(Products);
