import {
  ADD_USER_DATA,
  ADD_USER_DATA_FAILED,
  ADD_DEALS_DATA,
  ADD_DEALS_DATA_FAILED,
  IS_PRODUCT_ORDERED,
  IS_PRODUCT_ORDERED_FAILED,
  IS_CREW_USER,
  IS_CREW_USER_FAILED,
  IS_FAMILY_USER,
  IS_FAMILY_USER_FAILED,
} from './actions';

export const initialState = {
  firstname: '',
  lastname: '',
  email: '',
  points: 0,
  error: '',
  isCrewUser: false,
  isFamilyUser: false,
  isProductOrdered: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_USER_DATA:
      return { ...state, ...action.data };

    case ADD_USER_DATA_FAILED:
      return { ...state, error: action.error };

    case ADD_DEALS_DATA:
      return { ...state, ...action.data };

    case ADD_DEALS_DATA_FAILED:
      return { ...state, error: action.error };

    case IS_PRODUCT_ORDERED:
      return { ...state, isProductOrdered: action.data };

    case IS_PRODUCT_ORDERED_FAILED:
      return { ...state, error: action.error };

    case IS_CREW_USER:
      return { ...state, isCrewUser: action.data };

    case IS_CREW_USER_FAILED:
      return { ...state, error: action.error };

    case IS_FAMILY_USER:
      return { ...state, isFamilyUser: action.data };

    case IS_FAMILY_USER_FAILED:
      return { ...state, error: action.error };

    default:
      return state;
  }
};
