import React from 'react';
import PropTypes from 'prop-types';

import CardButton from 'components/Products/CardButton';

import {
  CardContainer,
  PreviewContainer,
  PreviewNimbus,
} from './ProductCard.styles';

const ProductCard = ({ name, image, price, onClick, inStock, limited }) => {
  return limited && inStock === 0 ? null : (
    <CardContainer
      onClick={onClick}
      className={inStock === 0 ? 'disabled-card' : null}
    >
      <PreviewContainer>
        <PreviewNimbus />
        <img alt={name} src={image} />
      </PreviewContainer>

      <strong>{price} ptn</strong>
      <span>{name}</span>

      <CardButton inStock={inStock} limited={limited} />
    </CardContainer>
  );
};

ProductCard.propTypes = {
  inStock: PropTypes.number.isRequired,
  limited: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ProductCard;
