export const ADD_PRODUCTS_DATA = 'ADD_PRODUCTS_DATA';
export const ADD_PRODUCTS_DATA_FAILED = 'ADD_PRODUCTS_DATA_FAILED';
export const SET_FILTER_TYPE = 'SET_FILTER_TYPE';
export const SET_FILTER_TYPE_FAILED = 'SET_FILTER_TYPE_FAILED';
export const ADD_FILTERED_PRODUCTS = 'ADD_FILTERED_PRODUCTS';
export const ADD_FILTERED_PRODUCTS_FAILED = 'ADD_FILTERED_PRODUCTS_FAILED';

export const addProductsData = (data) => (dispatch) => {
  try {
    dispatch({ type: ADD_PRODUCTS_DATA, data });
  } catch (error) {
    dispatch({ type: ADD_PRODUCTS_DATA_FAILED, error });
  }
};

export const setFilterType = (data) => (dispatch) => {
  try {
    dispatch({ type: SET_FILTER_TYPE, data });
  } catch (error) {
    dispatch({ type: SET_FILTER_TYPE_FAILED, error });
  }
};

export const addFilteredProducts = (data) => (dispatch) => {
  try {
    dispatch({ type: ADD_FILTERED_PRODUCTS, data });
  } catch (error) {
    dispatch({ type: ADD_FILTERED_PRODUCTS_FAILED, error });
  }
};