export default {
  // Description:
  question: (points) =>
    `Wil je <strong>${points} punten</strong> inruilen voor een`,

  missingPoints: (amount) =>
    `Zo dichtbij! Je hebt nog <strong>${amount} punten</strong> nodig voor de`,

  totalPoints: (points) =>
    `Je hebt in totaal <strong>${points} punten</strong>`,

  // FAQ:
  faqLink:
    'gmalite://gmalite-smartweb?weburl=https%3A%2F%2Fstage-faq.web.app%2F',
  termsLink:
    'gmalite://gmalite-smartweb?weburl=https%3A%2F%2Fstage-terms.web.app%2F',

  faqDelivery: 'McDonald’s Merchandise wordt gratis bij jou thuisbezorgd. Check de ',
  faqLinkText: 'veelgestelde vragen',
  termsLinkText: 'algemene voorwaarden',

  // Analytics:
  gtmFormButton: (code) => ({
    eventLabel: `/rewardshop/form/${code}`,
    eventAction: 'Ja, dat wil ik',
    event: 'button_click',
  }),

  gtmGoalButton: {
    eventLabel: '/loyalty/spaargoal/aanpassen',
    eventAction: 'Kies als spaargoal',
    event: 'button_click',
  },

  gtmBackButton: {
    eventLabel: '/rewardshop/overview',
    event: 'button_click',
    eventAction: 'Terug',
  },

  gtmFAQ: {
    eventLabel: '/rewardshop/faq',
    event: 'button_click',
    eventAction: 'faq',
  },

  // Buttons:
  chooseGoal: 'Kies als spaargoal',
  continue: 'Ja, dat wil ik!',
  back: 'Terug',
};
