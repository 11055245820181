import { combineReducers } from 'redux';
import user from './user/reducer';
import product from './product/reducer';
import products from './products/reducer';

export default combineReducers({
  user,
  product,
  products,
});
