import { CardsContainer } from './Banners.styles';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const Banners = ({ banners }) => {
  const navigate = useNavigate();

  const onCardClick = (link) => link && navigate(link);

  return (
    <CardsContainer>
      {banners.map((banner) => (
        <a
          key={banner.id}
          href={(!banner.isInternalLink && banner.link) || undefined}
        >
          <img
            onClick={
              (banner.isInternalLink && onCardClick.bind(null, banner.link)) ||
              undefined
            }
            src={banner.image}
          />
        </a>
      ))}
    </CardsContainer>
  );
};

Banners.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      isInternalLink: PropTypes.bool,
    }).isRequired
  ),
};

Banners.defaultProps = {
  banners: [],
};

export default Banners;
