export default {
  productOutOfStock: (productName) =>
    `Wat een pech! Iemand is je net voor geweest en heeft het laatste artikel "<strong>${productName}</strong>" besteld.`,

  // Submit:
  returnHome: 'Terug',

  gtmHomeButton: (code) => ({
    eventLabel: `/rewardshop/out-of-stock/${code}`,
    eventAction: 'ReturnHome',
    event: 'button_click',
  }),
};
