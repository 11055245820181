import React from 'react';
import Content from 'components/Product/content';

import FAQContainer from './FAQButton.styles';
import PropTypes from 'prop-types';
import { getIsFamilyUser } from '../../../redux/user/selectors';
import { connect } from 'react-redux';

const FAQButton = ({isFamilyUser}) => {

  return (
    <FAQContainer isFamily={isFamilyUser}>
      <span>{Content.faqDelivery}</span>
      <a onClick={() => window.location.href = Content.faqLink}>{Content.faqLinkText}</a>
      <span> of onze </span>
      <a onClick={() => window.location.href = Content.termsLink}>{Content.termsLinkText}</a>
    </FAQContainer>
  );
};

FAQButton.propTypes = {
  isFamilyUser: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isFamilyUser: getIsFamilyUser(state),
});

export default connect(mapStateToProps)(FAQButton);
