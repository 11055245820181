import React from 'react';
import PropTypes from 'prop-types';
import { Content } from 'components/Form';

import {
  FieldContainer,
  FieldLabel,
  FieldInput,
  FieldError
} from './InputField.styles';

const InputField = ({ name, type, placeholder, disabled, register, width, last, error, onBlur }) => (
  <FieldContainer width={width} last={last}>
    <FieldLabel htmlFor={name}>
      {Content[name]}
    </FieldLabel>

    <FieldInput
      id={name}
      type={type}
      {...register}
      onBlur={onBlur}
      disabled={disabled}
      placeholder={placeholder}
    />

    {error && <FieldError>{Content[`${name}Error`]}</FieldError>}
  </FieldContainer>
);

InputField.propTypes = {
  register: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  
  disabled: PropTypes.bool,
  width: PropTypes.number,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  error: PropTypes.bool,
  last: PropTypes.bool
};

InputField.defaultProps = {
  onBlur: () => void 0,
  placeholder: '',
  disabled: false,

  error: false,
  type: 'text',
  last: false,
  width: 100,
};

export default InputField;
