import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import lottie from 'lottie-web';
import { pushEvent } from 'hooks';
import lottieService from 'services/lottie.service';

import ProductData from 'assets/animations/product.json';
import RewardsData from 'assets/animations/rewards.json';
import FamilyRewardsData from 'assets/animations/family-rewards.json';

import { ZoomButton, ZoomPopup } from 'components/Product';
import AnimationContainer from './ProductAnimation.styles';
import { getIsFamilyUser } from '../../../redux/user/selectors';
import { connect } from 'react-redux';

const ProductAnimation = ({
  image,
  images,
  // largeImage,
  fanshop,
  top,
  zoom,
  code,
  isFamilyUser,
}) => {
  const [visiblePopup, setVisiblePopup] = useState(false);

  const onProductClick = useCallback(() => {
    setVisiblePopup(true);

    pushEvent({
      eventLabel: `/rewardshop/sufficient/${code}`,
      event: 'button_click',
      eventAction: 'Zoom',
    });
  }, [code]);

  const animationContainer = useRef(null);
  const animationLoaded = useRef(false);
  const productImage = useRef(null);

  const AnimationData =
    isFamilyUser && fanshop
      ? FamilyRewardsData
      : fanshop
      ? RewardsData
      : ProductData;

  const addClickEvent = useCallback(() => {
    productImage.current =
      animationContainer.current.querySelector('.png image');

    productImage.current.addEventListener('click', onProductClick, false);

    productImage.current.classList.add('product');
    animationLoaded.current = true;
  }, [onProductClick]);

  if (!fanshop) {
    AnimationData.assets[0].p = image;
  }

  useEffect(() => {
    const animation = lottieService({
      container: animationContainer.current,
      animationData: AnimationData,

      rendererSettings: {
        progressiveLoad: false,
      },
    });

    animation.playSegments(
      [
        [0, 100],
        [100, 300],
      ],
      true
    );

    animation.onEnterFrame = () =>
      zoom && !animationLoaded.current && addClickEvent();

    return () => {
      zoom &&
        productImage.current?.removeEventListener(
          'click',
          onProductClick,
          false
        );

      lottie.destroy();
    };
  }, [AnimationData, addClickEvent, onProductClick, zoom]);

  return (
    <>
      <AnimationContainer top={top}>
        <div ref={animationContainer} />
        {zoom && <ZoomButton />}
      </AnimationContainer>

      {visiblePopup && (
        <ZoomPopup
          onClose={() => setVisiblePopup(false)}
          // image={largeImage}
          images={images}
          code={code}
        />
      )}
    </>
  );
};

ProductAnimation.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  isFamilyUser: PropTypes.bool.isRequired,
  // largeImage: PropTypes.string,
  fanshop: PropTypes.bool,
  image: PropTypes.string,

  code: PropTypes.string,
  top: PropTypes.number,
  zoom: PropTypes.bool,
};

ProductAnimation.defaultProps = {
  // largeImage: '',
  fanshop: false,
  zoom: false,

  images: [],
  image: '',
  code: '',
  top: 0,
};

const mapStateToProps = (state) => ({
  isFamilyUser: getIsFamilyUser(state),
});

export default connect(mapStateToProps)(ProductAnimation);
