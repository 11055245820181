import React from 'react';
import PropTypes from 'prop-types';

import { Content } from 'components/Form';
import DescriptionContainer from './ProductDescription.styles';

const ProductDescription = ({ name }) => (
  <DescriptionContainer
    dangerouslySetInnerHTML={{
      __html: Content.productDescription(name)
    }}
  />
);

ProductDescription.propTypes = {
  name: PropTypes.string.isRequired
};

export default ProductDescription;
