import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import React, { useRef, useState, useEffect } from 'react';

import { Content, FIELDS, InputField } from 'components/Form';
import { POSTCODE_URL_API } from 'constants/api';
import { Button } from 'components/shared';

import {
  FormContainer,
  SectionTitle,
  FieldsContainer,
  Separator,
  TermsContainer,
  Checkbox,
  TermsLabel,
  TermsLink,
} from './Fields.styles';

import { FieldError } from '../InputField/InputField.styles';
import { getIsFamilyUser } from '../../../redux/user/selectors';
import { connect } from 'react-redux';

const Fields = ({ firstName, lastName, onSubmit, popup, isFamilyUser }) => {
  const isSubmitting = useRef(false);
  const startSubmitting = useRef(false);
  const fetchingPostalCode = useRef(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const houseNumberPattern = FIELDS[1].find(
    ({ name }) => name === 'houseNumber'
  ).pattern;

  const postalCodePattern = FIELDS[1].find(
    ({ name }) => name === 'postalCode'
  ).pattern;

  const {
    trigger,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue('firstName', firstName);
    setValue('lastName', lastName);
  }, [setValue, firstName, lastName]);

  useEffect(() => (startSubmitting.current = popup), [popup]);

  const getPostalCodeInfo = () => {
    const houseNumberAddition = getValues('suffix');
    const houseNumber = getValues('houseNumber');
    const postcode = getValues('postalCode');

    const params = new URLSearchParams({
      houseNumberAddition,
      houseNumber,
      postcode,
    });

    const validPostalCode = postalCodePattern.test(postcode);
    const validHouseNumber = houseNumberPattern.test(houseNumber);
    fetchingPostalCode.current = validPostalCode && validHouseNumber;

    fetchingPostalCode.current &&
      fetch(`${POSTCODE_URL_API}?${params}`).then((response) =>
        response.json().then(({ street, city }) => {
          fetchingPostalCode.current = false;
          setValue('streetName', street);
          setValue('venue', city);
          street && trigger(['streetName']);
          city && trigger(['venue']);

          if (isSubmitting.current) {
            trigger(['streetName', 'venue']);
            startSubmitting.current = true;
            isSubmitting.current = false;
            handleSubmit(onSubmit)();
          }
        })
      );
  };

  const onBeforeSubmit = (event) => {
    event.preventDefault();

    // if (startSubmitting.current) return;
    isSubmitting.current = fetchingPostalCode.current;

    if (!isSubmitting.current) {
      startSubmitting.current = true;
      handleSubmit(onSubmit)();
    }

    return false;
  };

  return (
    <FormContainer onSubmit={onBeforeSubmit}>
      <FieldsContainer>
        <SectionTitle>{Content.personalInformation}</SectionTitle>

        {FIELDS[0].map((field, f) => (
          <InputField
            {...field}
            key={field.name}
            error={!!errors[field.name]}
            last={f === FIELDS[0].length - 1}
            register={register(field.name, {
              required: field.required,
              ...(field.pattern && {
                pattern: field.pattern,
              }),
            })}
          />
        ))}

        <Separator />

        <SectionTitle>{Content.address}</SectionTitle>

        {FIELDS[1].map((field, f) => (
          <InputField
            {...field}
            key={field.name}
            onBlur={getPostalCodeInfo}
            error={!!errors[field.name]}
            last={f === FIELDS[1].length - 1}
            register={register(field.name, {
              required: field.required,
              ...(field.pattern && {
                pattern: field.pattern,
              }),
            })}
          />
        ))}

        <Separator />
      </FieldsContainer>

      <TermsContainer>
        <Checkbox
          id="terms"
          name="terms"
          type="checkbox"
          checked={termsAccepted}
          {...register('terms', { required: true })}
          onClick={() => setTermsAccepted(!termsAccepted)}
        />

        <TermsLabel>
          {Content.terms}&nbsp;
          <TermsLink href={Content.termsHref}>{Content.link}</TermsLink>.
        </TermsLabel>

        {errors.terms && (
          <FieldError margin={10}>{Content.termsError}</FieldError>
        )}
      </TermsContainer>

      <Button text={Content.submit} isFamily={isFamilyUser} type="submit" />
    </FormContainer>
  );
};

Fields.propTypes = {
  isFamilyUser: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  popup: PropTypes.bool,
};

Fields.defaultProps = {
  firstName: '',
  lastName: '',
  popup: false,
};

const mapStateToProps = (state) => ({
  isFamilyUser: getIsFamilyUser(state),
});

export default connect(mapStateToProps)(Fields);
