import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'redux/store';
import { ThemeProvider } from 'styled-components';

import App from './App';
import { theme } from './theme';
import { init as Sentry } from '@sentry/react';
import { browserTracingIntegration } from '@sentry/browser';
import { captureConsoleIntegration } from '@sentry/integrations';

process.env.REACT_APP_SENTRY_DSN && Sentry({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  tracesSampleRate: +process.env.REACT_APP_SENTRY_SAMPLE_RATE,

  integrations: [
    new browserTracingIntegration(),
    new captureConsoleIntegration({
      levels: process.env.REACT_APP_SENTRY_LOG_CAPTURE_LEVELS.split(', ')
    })
  ],

  ignoreErrors: [
    'Non-Error promise rejection captured',
    'McD Bridge already initialized',
    'Non-Error exception captured'
  ]
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
