import { functions } from 'lib/firebase';
import { httpsCallable } from 'firebase/functions';

export const fetchBanners = async ({ categories } = []) => {
  try {
    const response = await httpsCallable(
      functions,
      'getBanners'
    )({ categories });
    return response.data;
  } catch (error) {
    // Getting the Error details.
    const code = error.code;
    const message = error.message;
    const details = error.details;

    const errorLog = {
      code: code,
      message: message,
      details: details,
    };

    console.table(errorLog);
    throw error;
  }
};

export const fetchProductInLocalStock = async (id) => {
  try {
    const response = await httpsCallable(
      functions,
      'getProductInLocalStock'
    )(id);
    return response.data.inStock;
  } catch (error) {
    // Getting the Error details.
    const code = error.code;
    const message = error.message;
    const details = error.details;

    const errorLog = {
      code: code,
      message: message,
      details: details,
    };

    console.table(errorLog);
    throw error;
  }
};

export const fetchProductData = async (id) => {
  try {
    const response = await httpsCallable(functions, 'getProductData')(id);
    return response.data.product;
  } catch (error) {
    // Getting the Error details.
    const code = error.code;
    const message = error.message;
    const details = error.details;

    const errorLog = {
      code: code,
      message: message,
      details: details,
    };

    console.table(errorLog);
    throw error;
  }
};

export const fetchProductsList = async ({ categories } = []) => {
  try {
    const response = await httpsCallable(
      functions,
      'getProductsList'
    )({ categories });
    return response.data;
  } catch (error) {
    // Getting the Error details.
    const code = error.code;
    const message = error.message;
    const details = error.details;

    const errorLog = {
      code: code,
      message: message,
      details: details,
    };

    console.table(errorLog);
    throw error;
  }
};

export const createOrder = async ({
  code,
  firstName,
  lastName,
  email,
  postalCode,
  houseNumber,
  extension,
  street,
  city,
  termsAcknowledged,
  isCrew,
  isFamily,
}) => {
  try {
    const response = await httpsCallable(
      functions,
      'createOrder'
    )({
      code,
      firstName,
      lastName,
      email,
      postalCode,
      houseNumber,
      extension,
      street,
      city,
      termsAcknowledged,
      isCrew,
      isFamily,
    });

    return response.data;
  } catch (e) {
    return e;
  }
};

export const updateOrder = (data) =>
  httpsCallable(functions, 'updateOrder')(data);

export const placeOrder = (data) =>
  httpsCallable(functions, 'placeOrder')(data);

export const crewLogin = async (code) => {
  try {
    const response = await httpsCallable(functions, 'crewLogin')({ code });
    return response.data.code === 'ok';
  } catch (error) {
    // Getting the Error details.
    const code = error.code;
    const message = error.message;
    const details = error.details;

    const errorLog = {
      code: code,
      message: message,
      details: details,
    };

    console.table(errorLog);
    throw error;
  }
};
