import { createSelector } from 'reselect';

export const getUserState = createSelector(
  [(state) => state.user],
  (userState) => userState
);

export const getUserFirstname = createSelector(
  [getUserState],
  (userState) => userState.firstname
);

export const getUserLastname = createSelector(
  [getUserState],
  (userState) => userState.lastname
);

export const getUserEmail = createSelector(
  [getUserState],
  (userState) => userState.email
);

export const getDealsData = createSelector(
  [getUserState],
  (userState) => userState.points
);

export const getCrewUser = createSelector(
  [getUserState],
  (userState) => userState.isCrewUser
);

export const getIsFamilyUser = createSelector(
  [getUserState],
  (user) => user.isFamilyUser
);