import styled, { css } from 'styled-components';

export const ButtonContainer = styled.div(({ theme, show }) => css`
  box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.6);
  background-color: ${theme.colors.white};
  transition: transform 0.2s ease-out 3s;

  margin: 0 auto min(35vw, 150px);
  backface-visiblity: hidden;
  transform-origin: 50% 50%;

  pointer-evens: none;
  transform: scale(0);
  border-radius: 50%;

  position: absolute;
  display: block;

  height: 28px;
  width: 28px;
  z-index: 7;

  bottom: 0;
  right: 0;
  left: 0;

  ${show && css`
    transform: scale(1);
  `}
`);

export const ZoomIcon = styled.div(({ theme }) => css`
  border: 2px solid ${theme.colors.lightBlack};
  border-radius: 50%;

  position: absolute;
  display: block;

  padding: 3px;
  margin: auto;

  height: 18px;
  width: 18px;

  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  img {
    transform: rotate(45deg);
  }
`);
