export default [
  [
    {
      name: 'firstName',
      placeholder: '',
      required: true,
      width: 100,
    },
    {
      name: 'lastName',
      placeholder: '',
      required: true,
      width: 100,
    },
  ],

  [
    {
      pattern: /^[0-9]{4}\s?[a-zA-Z]{2}$/,
      name: 'postalCode',
      placeholder: '',
      required: true,
      width: 100,
    },
    {
      pattern: /^\d+$/,
      name: 'houseNumber',
      placeholder: '',
      required: true,
      width: 48,
    },
    {
      pattern: /^[a-zA-Z0-9]{1,5}$/,
      placeholder: '',
      name: 'suffix',
      width: 48,
    },
    {
      pattern: /^[^-\s][\w.,\s\\'-]+$|[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]/,
      name: 'streetName',
      placeholder: '',
      required: true,
      disabled: true,
      width: 100,
    },
    {
      pattern: /^[^-\s][\w.,\s\\'-]+$|[A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF]/,
      placeholder: '',
      required: true,
      disabled: true,
      name: 'venue',
      width: 100,
    },
  ],

  [
    {
      pattern: /^(\+31[1-9]{9}$)|^([0-9]{10}$)/,
      name: 'phoneNumber',
      placeholder: '',
      required: true,
      type: 'tel',
      width: 100,
    },
    {
      placeholder: 'DD/MM/JJJJ',
      name: 'dateBirth',
      type: 'date',
      width: 100,
    },
  ],
];
