import React from 'react';
import PropTypes from 'prop-types';

import Content from 'components/Products/CardButton/content';
import Button from './CardButton.styles';

const CardButton = ({ inStock, limited, ...props }) =>
  !limited ? (
    <Button green={inStock > 100} disabled={inStock === 0} {...props}>
      {inStock === 0
        ? Content.outStock
        : inStock <= 100
        ? `${Content.only} ${inStock}`
        : Content.inStock}
    </Button>
  ) : (
    <Button purple {...props}>
      {Content.isLimited}
    </Button>
  );

CardButton.propTypes = {
  inStock: PropTypes.number.isRequired,
  limited: PropTypes.bool.isRequired,
};

export default CardButton;
